import { createTheme } from "@mui/material/styles";
import tokenDataLight from "./tokens/tokens-light.json";
import typography from "./tokens/typography.json";
import { colorsToTokens, fontsToTokens } from "./helper";
import zIndex from "@mui/material/styles/zIndex";

const colorsLight = colorsToTokens(tokenDataLight);
const typographyTokens = fontsToTokens(typography);

const lightTheme = createTheme({
  name: "vapor-light",
  palette: {
    primary: {
      main: colorsLight.textDefaultColor,
      textDisabledColor: colorsLight.textDisabledColor,
      textHintColor: colorsLight.textHintColor,
      background: colorsLight.backgroundDefaultColor,
      backgroudProminent: colorsLight.backgroundProminentColor,
      surfaceDefault: colorsLight.surfaceDefaultColor,
      borderDefault: colorsLight.borderDefaultColor,
      borderSuccessed: colorsLight.baseSuccessColor + " !important",
      borderError: colorsLight.borderCriticalColor + " !important",
      borderDisabled: colorsLight.borderDisabledColor + " !important",
      borderActive: colorsLight.borderActiveColor + " !important",
      successed: colorsLight.baseSuccessColor,
      textSubduedColor: colorsLight.textSubduedColor,
      error: colorsLight.textCriticalColor,
      iconDefault: colorsLight.iconDefaultColor,
      iconDisabled: colorsLight.iconDisabledColor,
      iconInverse: colorsLight.iconInverseColor,
      interactiveDefault: colorsLight.interactiveDefaultColor,
      interactiveSelectedColor: colorsLight.interactiveSelectedColor,
      interactiveDisabledColor: colorsLight.interactiveDisabledColor,
      inputBackgroundDefault: colorsLight.inputBackgroundDefaultColor,
      inputBackgroundActive: colorsLight.inputBackgroundActiveColor,
      baseVaporGradient: colorsLight.baseVaporGradientColor,
      tertiaryTextDefault: colorsLight.tertiaryTextDefaultColor,
      tertiaryTextHoverColor: colorsLight.tertiaryTextHoverColor,
      tertiaryBackgroundActiveColor: colorsLight.tertiaryBackgroundActiveColor,
      primaryBackgroundHover: colorsLight.primaryBackgroundHoverColor,
      primary90Color: colorsLight.primary90Color,
      primary80Color: colorsLight.primary80Color,
      primary60Color: colorsLight.primary60Color,
      neutral50Color: colorsLight.neutral50Color,
      neutral10Color: colorsLight.neutral10Color,
      baseWhiteColor: colorsLight.baseWhiteColor,
      baseInfoColor: colorsLight.baseInfoColor,
      baseWarningColor: colorsLight.baseWarningColor,
      baseCriticalColor: colorsLight.baseCriticalColor,
      textTitleColor: colorsLight.textTitleColor,
      primaryTextDefaultColor: colorsLight.primaryTextDefaultColor,
      primary: colorsLight.primary60Color,
      primaryBackgroundDisabled: colorsLight.primaryBackgroundDisabledColor,
      primaryBackgroundDefault: colorsLight.primaryBackgroundDefaultColor,
      inputBackgroundDisabledColor: colorsLight.inputBackgroundDisabledColor,
      inputBackgroundReadonlyColor: colorsLight.inputBackgroundReadonlyColor,
      interactiveDefaultColor: colorsLight.interactiveDefaultColor,
      iconInfoColor: colorsLight.iconInfoColor,
      iconPrimaryColor: colorsLight.primary60Color,
      accentBlueSapphire: colorsLight.accentBlueSapphireColor, // new colors
      accentIslamicGreen: colorsLight.accentIslamicGreenColor,
      accentMaximumPurple: colorsLight.accentMaximumPurpleColor,
      accentMediumSlateBlue: colorsLight.accentMediumSlateBlueColor,
      accentOgreOdor: colorsLight.accentOgreOdorColor,
      accentPrune: colorsLight.accentPruneColor,
      accentRoyalFuchsia: colorsLight.accentRoyalFuchsiaColor,
      accentRussianViolet: colorsLight.accentRussianVioletColor,
      accentRustyRed: colorsLight.accentRustyRedColor,
      backgroundTooltip: colorsLight.backgroundTooltipColor,
      baseTransparent: colorsLight.baseTransparentColor,
      borderHover: colorsLight.borderHoverColor,
      borderInfo: colorsLight.borderInfoColor,
      borderSuccess: colorsLight.borderSuccessColor,
      borderWarning: colorsLight.borderWarningColor,
      criticalBackgroundActive: colorsLight.criticalBackgroundActiveColor,
      criticalBackgroundDefault: colorsLight.criticalBackgroundDefaultColor,
      criticalBackgroundDisabled: colorsLight.criticalBackgroundDisabledColor,
      criticalBackgroundHover: colorsLight.criticalBackgroundHoverColor,
      iconActive: colorsLight.iconActiveColor,
      iconCritical: colorsLight.iconCriticalColor,
      iconHover: colorsLight.iconHoverColor,
      iconSuccess: colorsLight.iconSuccessColor,
      iconWarning: colorsLight.iconWarningColor,
      inputBackgroundHover: colorsLight.inputBackgroundHoverColor,
      interactiveActive: colorsLight.interactiveActiveColor,
      interactiveFocused: colorsLight.interactiveFocusedColor,
      interactiveHover: colorsLight.interactiveHoverColor,
      neutral3: colorsLight.neutral3Color,
      neutral5: colorsLight.neutral5Color,
      neutral20: colorsLight.neutral20Color,
      neutral30: colorsLight.neutral30Color,
      neutral40: colorsLight.neutral40Color,
      neutral60: colorsLight.neutral60Color,
      neutral70: colorsLight.neutral70Color,
      neutral80: colorsLight.neutral80Color,
      neutral90: colorsLight.neutral90Color,
      neutral100: colorsLight.neutral100Color,
      primary5: colorsLight.primary5Color,
      primary10: colorsLight.primary10Color,
      primary20: colorsLight.primary20Color,
      primary30: colorsLight.primary30Color,
      primary40: colorsLight.primary40Color,
      primary50: colorsLight.primary50Color,
      primary70: colorsLight.primary70Color,
      primaryBackgroundActive: colorsLight.primaryBackgroundActiveColor,
      primaryTextActive: colorsLight.primaryTextActiveColor,
      primaryTextHover: colorsLight.primaryTextHoverColor,
      secondaryBackgroundActive: colorsLight.secondaryBackgroundActiveColor,
      secondaryBackgroundDefault: colorsLight.secondaryBackgroundDefaultColor,
      secondaryBackgroundDisabled: colorsLight.secondaryBackgroundDisabledColor,
      secondaryBackgroundHover: colorsLight.secondaryBackgroundHoverColor,
      secondaryBorderActive: colorsLight.secondaryBorderActiveColor,
      secondaryBorderDefault: colorsLight.secondaryBorderDefaultColor,
      secondaryBorderDisabled: colorsLight.secondaryBorderDisabledColor,
      secondaryBorderHover: colorsLight.secondaryBorderHoverColor,
      secondaryTextActive: colorsLight.secondaryTextActiveColor,
      secondaryTextDefault: colorsLight.secondaryTextDefaultColor,
      secondaryTextDisabled: colorsLight.secondaryTextDisabledColor,
      secondaryTextHover: colorsLight.secondaryTextHoverColor,
      surfaceActive: colorsLight.surfaceActiveColor,
      surfaceDisabled: colorsLight.surfaceDisabledColor,
      surfaceHover: colorsLight.surfaceHoverColor,
      tertiaryBackgroundDefault: colorsLight.tertiaryBackgroundDefaultColor,
      tertiaryBackgroundDisabled: colorsLight.tertiaryBackgroundDisabledColor,
      tertiaryBackgroundHover: colorsLight.tertiaryBackgroundHoverColor,
      tertiaryTextActive: colorsLight.tertiaryTextActiveColor,
      tertiaryTextDisabled: colorsLight.tertiaryTextDisabledColor,
      textInfo: colorsLight.textInfoColor,
      textInverse: colorsLight.textInverseColor,
      textOnProminent: colorsLight.textOnProminentColor,
      textSuccess: colorsLight.textSuccessColor,
      textWarning: colorsLight.textWarningColor,
      // ---- abstract colors ----
      richElectricBlue: colorsLight.abstractRichElectricBlueColor,
      cyanProgress: colorsLight.abstractCyanProgressColor,
      darkImperialBlue: colorsLight.abstractDarkImperialBlueColor,
      columbiaBlue: colorsLight.abstractColumbiaBlueColor,
      azure: colorsLight.abstractAzureColor,
      diamond: colorsLight.abstractDiamondColor,
      white: colorsLight.abstractWhiteColor,
      whiteSmoke: colorsLight.abstractWhiteSmokeColor,
      azureishWhite: colorsLight.abstractAzureishWhiteColor,
      yankeesBlue: colorsLight.abstractYankeesBlueColor,
      cadet: colorsLight.abstractCadetColor,
      quickSilver: colorsLight.abstractQuickSilverColor,
      silverSand: colorsLight.abstractSilverSandColor,
      gainsboro: colorsLight.abstractGainsboroColor,
      ghostWhite: colorsLight.abstractGhostWhiteColor,
      laSalleGreen: colorsLight.abstractLaSalleGreenColor,
      crayola: colorsLight.abstractCrayolaColor,
      maximumRed: colorsLight.abstractMaximumRedColor
    },
    // appBar: { main: colorsLight.primary90Color },
    secondary: {
      main: colorsLight.interactiveDefaultColor
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontFamily: ["Roboto", "Cairo"].join(","),
    titleMedium: {
      ...typographyTokens.titleMediumTypography,
      color: colorsLight.textTitleColor
    },
    titleSmall: {
      ...typographyTokens.titleSmallTypography,
      color: colorsLight.textTitleColor
    },
    titleXSmall: {
      ...typographyTokens.titleSmallTypography,
      color: colorsLight.textTitleColor,
      fontSize: 16
    },
    titleLarge: {
      ...typographyTokens.titleLargeTypography,
      color: colorsLight.textTitleColor
    },
    bodySmall: {
      ...typographyTokens.bodySmallTypography,
      color: colorsLight.textDefaultColor
    },
    body: {
      ...typographyTokens.bodyTypography,
      color: colorsLight.textDefaultColor
    },
    captionSmall: {
      ...typographyTokens.captionSmallTypography,
      color: colorsLight.textDefaultColor
    },
    caption: {
      ...typographyTokens.captionTypography,
      color: colorsLight.textDefaultColor
    },
    display: {
      ...typographyTokens.displayTypography,
      color: colorsLight.textTitleColor
    },
    eyebrow: {
      ...typographyTokens.eyebrowTypography,
      color: colorsLight.textDefaultColor
    },
    heading: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.headingTypography
    },
    pageDescription: {
      fontFamily: "Cairo",
      color: colorsLight.textSubduedColor,
      fontWeight: "400",
      fontSize: "12px"
    },
    label: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    boldLabel: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    labelDisabled: {
      color: colorsLight.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    formLabelDisabled: {
      color: colorsLight.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "400"
    },
    filedText: {
      color: colorsLight.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "400"
    },
    leadXLarge: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.leadXLargeTypography
    },
    leadLarge: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.leadLargeTypography
    },
    leadMedium: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.leadMediumTypography
    },
    leadSmall: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.leadSmallTypography
    },
    displayLarge: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.displayLargeTypography
    },
    displayMedium: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.displayMediumTypography
    },
    displaySmall: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.displaySmallTypography
    },
    subtitle: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.subtitleTypography
    },
    body500: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.body500Typography
    },
    body700: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.body700Typography
    },
    bodySmall500: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.bodySmall500Typography
    },
    bodySmall700: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.bodySmall700Typography
    },
    bodyLarge: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.bodyLargeTypography
    },
    bodyLarge500: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.bodyLarge500Typography
    },
    bodyLarge700: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.bodyLarge700Typography
    },
    buttonLarge: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.buttonLargeTypography
    },
    button: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.buttonTypography
    },
    codeInput: {
      color: colorsLight.textDefaultColor,
      ...typographyTokens.codeInputTypography
    }
  },
  spacing: 8,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          "&.MuiBackdrop-root.MuiBackdrop-root": {
            background: colorsLight.surfaceDefaultColor,
            opacity: 0.5 + "!important"
          },
          "&.MuiBackdrop-root.MuiBackdrop-invisible": {
            background: "transparent"
          }
        }
      }
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: "alert" },
          style: {
            "&.MuiDivider-root": {
              background: colorsLight.baseCriticalColor,
              border: "none",
              height: 2
            }
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          lineHeight: "16px",
          boxShadow: "none",
          ...typographyTokens.buttonTypography
        }
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: colorsLight.primaryTextDefaultColor,
            background: colorsLight.baseVaporGradientColor,
            textShadow: "1px 1px 0 rgba(21,41,53,0.24)",
            borderRadius: 2,
            "&.MuiButton-root.MuiButton-contained.Mui-disabled ": {
              background: colorsLight.primaryBackgroundDisabledColor,
              color: colorsLight.primaryTextDisabledColor,
              textShadow: "none"
            },
            "&:active,&:hover,&:focus": {
              background:
                "linear-gradient(140deg, " +
                colorsLight.primaryBackgroundHoverColor +
                " 100%, " +
                colorsLight.primaryBackgroundHoverColor +
                " 100%)",
              color: "colorsLight.primaryTextHoverColor",
              boxShadow: "none"
            },
            "&.MuiButton-containedError": {
              background: colorsLight.criticalBackgroundDefaultColor,
              "&:hover": {
                background: colorsLight.criticalBackgroundHoverColor,
                color: colorsLight.primaryTextHoverColor
              },
              "&.MuiButton-root.MuiButton-contained.Mui-disabled ": {
                background: colorsLight.criticalBackgroundDisabledColor,
                color: colorsLight.primaryTextDisabledColor,
                textShadow: "none"
              },
              "&:active,&:hover,&:focus": {
                background: colorsLight.criticalBackgroundActiveColor
              }
            }
          }
        },
        {
          props: { variant: "outlined" },
          style: {
            color: colorsLight.secondaryTextDefaultColor,
            border: "1px solid " + colorsLight.secondaryBorderDefaultColor,
            borderRadius: 2,
            "&:hover": {
              border: "1px solid " + colorsLight.secondaryBackgroundHoverColor,
              color: colorsLight.secondaryTextHoverColor,
              background: colorsLight.secondaryBackgroundHoverColor
            },
            "&:active,&:focus": {
              border: "1px solid " + colorsLight.secondaryBackgroundActiveColor,
              color: colorsLight.secondaryTextActiveColor,
              background: colorsLight.secondaryBackgroundActiveColor
            },
            "&.MuiButton-root.MuiButton-outlined.Mui-disabled ": {
              border:
                "1px solid " + colorsLight.borderDisabledColor + " !important",
              color: colorsLight.borderDisabledColor,
              textShadow: "none"
            },
            "&.MuiButton-outlinedError": {
              color: colorsLight.baseCriticalColor,
              border:
                "1px solid " + colorsLight.baseCriticalColor + "!important",
              "&:hover,&:hover,&:focus": {
                border: "1px solid " + colorsLight.baseCriticalColor,
                color: "white",
                background: colorsLight.baseCriticalColor
              },
              "&.MuiButton-root.MuiButton-outlined.Mui-disabled ": {
                border:
                  "1px solid " +
                  colorsLight.borderDisabledColor +
                  " !important",
                color: colorsLight.borderDisabledColor,
                textShadow: "none"
              }
            }
          }
        },
        {
          props: { variant: "text" },
          style: {
            color: colorsLight.tertiaryTextDefaultColor,
            background: colorsLight.tertiaryBackgroundDefaultColor,
            border: "transparent",
            borderRadius: 0,
            "&:hover,&:active,&:focus": {
              borderRadius: 0,
              background: colorsLight.tertiaryBackgroundHoverColor,
              border: "transparent",
              color: colorsLight.tertiaryTextHoverColor
            },
            "&.MuiButton-root.MuiButton-text.Mui-disabled ": {
              color: colorsLight.tertiaryTextDisabledColor
            },
            "&.MuiButton-textError": {
              color: colorsLight.baseCriticalColor,
              border: "transparent",
              "&:hover,&:hover,&:focus": {
                border: "1px solid " + colorsLight.baseCriticalColor,
                color: "white",
                background: colorsLight.baseCriticalColor,
                border: "transparent"
              },
              "&.MuiButton-root.MuiButton-text.Mui-disabled ": {
                color: colorsLight.tertiaryTextDisabledColor
              }
            }
          }
        }
      ]
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: "transparent",
          borderRadius: 0,
          "&.MuiIconButton-root.MuiIconButton-colorPrimary": {
            color: colorsLight.tertiaryTextDefaultColor,
            background: colorsLight.tertiaryBackgroundDefaultColor,
            "&:hover,&:active,&:focus": {
              borderRadius: 0,
              background: colorsLight.tertiaryBackgroundHoverColor,
              border: "transparent",
              color: colorsLight.tertiaryTextHoverColor
            },
            "&.Mui-disabled ": {
              color: colorsLight.tertiaryTextDisabledColor
            }
          },
          "&.Mui-disabled ": {
            color: colorsLight.tertiaryTextDisabledColor
          },
          "&.MuiIconButton-root.MuiIconButton-colorError": {
            color: colorsLight.baseCriticalColor,
            border: "transparent",
            "&:hover,&:hover,&:focus": {
              border: "1px solid " + colorsLight.baseCriticalColor,
              color: "white",
              background: colorsLight.baseCriticalColor,
              border: "transparent"
            },
            "&.Mui-disabled ": {
              color: colorsLight.tertiaryTextDisabledColor
            }
          },
          "&.MuiIconButton-root.MuiIconButton-colorSecondary": {
            color: "white",
            border: "transparent",
            "&:hover": {
              color: "white",
              background: "transparent",
              border: "1px solid " + colorsLight.borderHoverColor
            },
            "&:focus": {
              color: "white",
              background: colorsLight.baseVaporGradientColor,
              border: "transparent"
            }
          },
          "&.MuiButtonBase-root.MuiIconButton-root.IconButton-root:focus": {
            color: colorsLight.baseCriticalColor,
            borderRadius: 0,
            "&:focus": {
              borderRadius: 0,
              color: colorsLight.baseCriticalColor
            }
          },
          "&.MuiIconButton-edgeEnd": {
            marginRight: "-8px"
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiFormLabel-root.MuiInputLabel-root": {
            ...typographyTokens.bodyTypography,
            color: colorsLight.textDefaultColor,
            fontWeight: 500,
            margin: 0,
            marginBottom: "8px",
            position: "relative",
            transform: "unset",
            WebkitTransform: "unset",
            "&.Mui-disabled": {
              color: colorsLight.textDisabledColor
            }
          }
        }
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            "&.MuiFormLabel-root.MuiInputLabel-root": {
              marginBottom: 6
            }
          }
        }
      ]
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.MuiFormHelperText-root": {
            ...typographyTokens.captionTypography,
            color: colorsLight.textSubduedColor,
            marginLeft: 0,
            marginRight: 0,
            marginTop: "8px",
            "&.Mui-error": {
              color: colorsLight.textCriticalColor
            },
            "&.Mui-disabled": {
              color: colorsLight.textDisabledColor
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ...typographyTokens.bodyTypography,
          minHeight: 40,
          borderRadius: 0,
          padding: 0,
          background: colorsLight.inputBackgroundDefaultColor,
          color: colorsLight.textDefaultColor,
          "& legend": {
            display: "none"
          },
          "& fieldset": { top: 0 },
          "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active,input:-internal-autofill-selected":
            {
              backgroundColor: "red",
              " -webkit-box-shadow":
                "0 0 0 30px " +
                colorsLight.inputBackgroundDefaultColor +
                " inset !important"
            },
          "fieldset ": {
            borderColor: colorsLight.borderDefaultColor
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid",
            borderColor: colorsLight.borderDefaultColor,
            "fieldset ": {
              borderColor: colorsLight.borderDefaultColor
            }
          },
          "& .MuiFormLabel-root.MuiInputLabel-root": {
            margin: 0,
            ...typographyTokens.bodyTypography,
            color: colorsLight.textDefaultColor,
            fontWeight: 500,
            marginBottom: 8,
            position: "relative !important"
          },

          "&.Mui-focused": {
            background: colorsLight.inputBackgroundActiveColor
          },

          "&.MuiOutlinedInput-root": {
            "&:hover fieldset ": {
              border: "1px solid " + colorsLight.borderActiveColor, //set color border input hover
              "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline":
                {
                  borderColor: colorsLight.borderCriticalColor
                }
            },

            "&.Mui-focused fieldset": {
              border: "1px solid " + colorsLight.borderActiveColor
            }
          },

          "&.Mui-disabled": {
            backgroundColor:
              colorsLight.inputBackgroundDisabledColor + " !important",
            fieldset: {
              border:
                "1px solid " + colorsLight.borderDisabledColor + " !important"
            },
            "&:hover fieldset ": {
              border:
                "1px solid " + colorsLight.borderDisabledColor + " !important",
              cursor: "not-allowed",
              pointerEvents: "all !important"
            }
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: colorsLight.textDisabledColor + " !important"
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            borderRadius: 0,
            padding: "10px 8px",
            height: "20px",
            "&:read-only": {
              backgroundColor:
                colorsLight.inputBackgroundReadonlyColor + " !important"
            },
            "&:hover": {
              cursor: "auto",
              "&:disabled": {
                cursor: "not-allowed",
                pointerEvents: "all !important"
              }
            }
          }
        }
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            minHeight: 32,
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              borderRadius: 0,
              padding: "6px 8px",
              height: "20px"
            },
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              marginBottom: 6
            }
          }
        },
        {
          props: { error: true },
          style: {
            "&.MuiOutlinedInput-root": {
              fieldset: {
                border: "1px solid " + colorsLight.borderCriticalColor
              },
              "&.Mui-focused fieldset": {
                border: "1px solid " + colorsLight.borderCriticalColor
              },
              "&:hover fieldset ": {
                border: "1px solid " + colorsLight.borderCriticalColor, //set color border input hover
                "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid " + colorsLight.borderCriticalColor
                  }
              }
            }
          }
        }
      ]
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            color: colorsLight.textDefaultColor,
            ...typographyTokens.bodyTypography
          },
          "& .Mui-disabled": {
            color: colorsLight.textDisabledColor
          },

          "& .MuiFormControlLabel-label.Mui-disabled": {
            color: colorsLight.textDisabledColor
          }
        }
      }
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root.MuiIconButton-root": {
            color: colorsLight.iconDefaultColor,
            "&.Mui-disabled": {
              color: colorsLight.iconDisabledColor
            }
          },
          "&.MuiPickersDay-root": {
            color: colorsLight.textDefaultColor,
            ...typographyTokens.bodyTypography,
            background: "transparent",
            border: "none"
          },
          "&.MuiPickersDay-root.MuiPickersDay-today": {
            background: "transparent",
            color: colorsLight.textDefaultColor,
            ...typographyTokens.bodyTypography,
            fontWeight: 700,
            border: "none"
          },
          "&.MuiPickersDay-root.Mui-selected": {
            background: colorsLight.baseVaporGradientColor,
            borderRadius: "50%",
            color: colorsLight.baseWhiteColor,
            ...typographyTokens.bodyTypography,
            fontWeight: 700,
            border: "none"
          },
          "&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall":
            {
              color: colorsLight.tertiaryTextDefaultColor
            },
          "&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall":
            {
              color: colorsLight.tertiaryTextDefaultColor
            },
          "&.MuiButtonBase-root.MuiMenuItem-root.MenuItem-root.Mui-selected": {
            background: colorsLight.interactiveSelectedColor
          },
          "&.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root":
            {
              background: colorsLight.interactiveSelectedColor,
              fontWeight: "400 !important"
            },
          "&.MuiButtonBase-root.MuiMenuItem-root": {
            ...typographyTokens.bodyTypography,
            border: "1px solid transparent"
          },
          "&.MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible": {
            background: colorsLight.interactiveSelectedColor
          },
          "&.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.MuiButtonBase-root":
            {
              color: colorsLight.textDefaultColor
            }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          "&.MuiListSubheader-root.MuiListSubheader-gutters.MuiListSubheader-sticky":
            {
              color: colorsLight.textDefaultColor
            }
        }
      }
    },

    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          "&.MuiDateRangePickerDay-rangeIntervalDayHighlight": {
            background: colorsLight.interactiveSelectedColor
          },
          "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            color: colorsLight.textDefaultColor,
            background: colorsLight.surfaceDefaultColor,
            "& .MuiSvgIcon-root": {
              color: colorsLight.tertiaryTextDefaultColor
            }
          }
        }
      }
    },

    MuiDateCalendar: {
      styleOverrides: {
        root: {
          ...typographyTokens.bodyTypography,
          color: colorsLight.textDefaultColor,
          background: colorsLight.surfaceDefaultColor,
          "& .MuiSvgIcon-root": {
            color: colorsLight.tertiaryTextDefaultColor
          },
          "& .MuiTypography-root.MuiTypography-caption": {
            ...typographyTokens.eyebrowTypography,
            color: colorsLight.textDisabledColor
          }
        }
      }
    },

    MuiYearCalendar: {
      styleOverrides: {
        root: {
          ...typographyTokens.bodyTypography,
          "& .PrivatePickersYear-root button": {
            color: colorsLight.textDefaultColor
          },
          "& .PrivatePickersYear-root .Mui-selected": {
            background: colorsLight.baseVaporGradientColor
          },
          "& .MuiPickersYear-yearButton.Mui-selected": {
            background: colorsLight.baseVaporGradientColor
          }
        }
      }
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: colorsLight.interactiveDefaultColor,
          ...typographyTokens.bodyTypography,
          "&.MuiLink-underlineAlways": {
            textDecoration: "underline",
            textDecorationColor: colorsLight.interactiveDefaultColor
          }
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
            ...typographyTokens.bodyTypography,
            color: colorsLight.textDefaultColor,
            background: colorsLight.surfaceDefaultColor
          },
          "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            color: colorsLight.textDefaultColor,
            background: colorsLight.surfaceDefaultColor
          }
        }
      },
      variants: [
        {
          props: { variant: "menuItem" },
          style: {
            "&.MuiPaper-root.MuiPaper-menuItem.MuiPopover-paper": {
              top: "48px !important",
              left: "48px !important",
              width: 200,
              height: "100%",
              background: colorsLight.surfaceDefaultColor,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        }
      ]
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
          background: colorsLight.backgroundDefaultColor
        }
      },
      variants: [
        {
          props: { variant: "navigation" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-root": {
              width: "inherit",
              position: "fixed",
              background: colorsLight.primary90Color
            }
          }
        },
        {
          props: { variant: "regular" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "small" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 48,
              height: 48,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "medium" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "large" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 72,
              height: 72,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "surface" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56
            }
          }
        },
        {
          props: { variant: "surface", size: "small" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 48,
              height: 48
            }
          }
        },
        {
          props: { variant: "surface", size: "medium" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56
            }
          }
        },
        {
          props: { variant: "surface", size: "large" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 72,
              height: 72
            }
          }
        }
      ]
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 2,
          backgroundColor: colorsLight.backgroundProminentColor,
          ...typographyTokens.captionTypography,
          color: "#ffffff"
        },
        arrow: {
          "&::before": {
            backgroundColor: colorsLight.backgroundProminentColor
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "&.MuiInputAdornment-root": {
            marginLeft: 8,
            marginRight: 8,
            "& .MuiTypography-root": {
              ...typographyTokens.bodyTypography,
              color: colorsLight.textDefaultColor
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#0090D1",
          "&.MuiCheckbox-root.Mui-checked": {
            color: "#0090D1"
          },
          "&.MuiCheckbox-root.Mui-disabled": {
            color: colorsLight.textDisabledColor
          },
          "&.MuiCheckbox-root.Mui-disabled.Mui-checked": {
            color: colorsLight.textDisabledColor
          },
          "&.MuiCheckbox-root.MuiCheckbox-indeterminate": {
            color: "#0090D1"
          },
          "&.MuiCheckbox-root.MuiCheckbox-indeterminate.Mui-disabled.Mui-checked":
            {
              color: colorsLight.textDisabledColor
            }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colorsLight.textDefaultColor
        },
        root: {
          zIndex: 4
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: zIndex.drawer,
          "& .MuiListItemIcon-root": {
            color: "#fff"
          },
          "& .MuiListItemText-root": {
            color: "#fff"
          }
        },
        paper: {
          backgroundColor: colorsLight.textDefaultColor
        }
      }
    },
    MuiAccordion: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            "&.MuiAccordion-root": {
              border: "1px solid " + colorsLight.borderCriticalColor
            },
            "&.MuiAccordion-root .MuiAccordionSummary-content": {
              marginRight: 0
            },
            "&.MuiAccordion-root .MuiAccordionSummary-root .error-icon": {
              display: "block"
            }
          }
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: "14px",
          color: colorsLight.textDefaultColor
        },
        option: {
          "&:hover": {
            border: "1px solid " + colorsLight.interactiveDefaultColor,
            backgroundColor: colorsLight.surfaceDefaultColor + " !important"
          },
          '&[aria-selected="true"]': {
            backgroundColor: "#DEF0F7 !important"
          },
          "& .MuiAutocomplete-option.MuiFocused": {
            backgroundColor: "#DEF0F7 !important"
          },
          border: "1px solid transparent"
        },
        root: {
          "& .MuiAutocomplete-option.MuiFocused": {
            backgroundColor: "#DEF0F7 !important"
          }
        }
      }
    },
    MuiTabs: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 72
            }
          }
        },
        {
          props: { orientation: "vertical" },
          style: {
            "&.MuiTabs-root.MuiTabs-vertical": {
              "& .MuiTab-root:hover": {
                background: colorsLight.interactiveSelectedColor
              }
            }
          }
        },
        {
          props: { variant: "sticky", orientation: "vertical" },
          style: {
            "&.MuiTabs-root.MuiTabs-vertical": {
              right: "0 !important",
              "& .MuiTab-root:hover": {
                background: colorsLight.interactiveSelectedColor
              }
            }
          }
        },
        {
          props: { variant: "standard", size: "medium" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 72
            }
          }
        },
        {
          props: { variant: "standard", size: "small" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              "& .MuiTab-root": {
                fontSize: 14
              }
            }
          }
        },
        {
          props: { variant: "scrollable", size: "small" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              "& .MuiTab-root": {
                fontSize: 14
              }
            }
          }
        },
        {
          props: { variant: "secondary" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator": {
              height: 0
            },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              alignItems: "center"
            },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root":
              {
                padding: "4px 12px",
                margin: "0 12px",
                fontSize: 14,
                fontFamily: "Cairo",
                fontWeight: 600,
                color: colorsLight.textSubduedColor,
                ":hover": {
                  minHeight: 32,
                  height: 32,
                  background: colorsLight.interactiveDefaultColor,
                  color: colorsLight.baseWhiteColor,
                  padding: "4px 12px",
                  borderBottom: "0 !important",
                  borderRadius: 2
                }
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-selected":
              {
                minHeight: 32,
                height: 32,
                background: "#B9E6F6",
                color: colorsLight.textTitleColor,
                padding: "4px 12px",
                borderBottom:
                  "1px solid " +
                  colorsLight.interactiveDefaultColor +
                  " !important",
                border: "1px solid " + colorsLight.interactiveDefaultColor,
                borderRadius: 2
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-disabled":
              {
                margin: "0 12px",
                color: "#D8D8D9",
                border: "none",
                background: "transparent"
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-disabled:hover":
              {
                background: "transparent",
                borderBottom: "0 !important"
              }
          }
        }
      ]
    },

    MuiTab: {
      variants: [
        {
          props: { variant: "activeStep" },
          style: {
            "&.MuiTab-root	": {
              borderBottomWidth: 106,
              paddingRight: "48px !important",
              clipPath:
                "polygon(0% 0%, calc(100% - 24px) 0%, 100% 50%, calc(100% - 24px) 100%, 0% 100%)"
            }
          }
        }
      ]
    },

    MuiChip: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentRussianVioletColor,
              borderRadius: 4,
              padding: "4px 8px",
              height: 24,
              "& .MuiChip-label": {
                ...typographyTokens.captionTypography,
                color: colorsLight.baseWhiteColor,
                padding: 0
              },
              ":hover": {
                background: colorsLight.accentRussianVioletColor,
                "& .MuiChip-label": {
                  ...typographyTokens.captionTypography,
                  color: colorsLight.baseWhiteColor,
                  padding: 0
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "blueSapphire" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentBlueSapphireColor,
              ":hover": {
                background: colorsLight.accentBlueSapphireColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "islamicGreen" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentIslamicGreenColor,
              ":hover": {
                background: colorsLight.accentIslamicGreenColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "russianViolet" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentRussianVioletColor,
              ":hover": {
                background: colorsLight.accentRussianVioletColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "maximumPurple" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentMaximumPurpleColor,
              ":hover": {
                background: colorsLight.accentMaximumPurpleColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "mediumSlateBlue" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentMediumSlateBlueColor,
              ":hover": {
                background: colorsLight.accentMediumSlateBlueColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "prune" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentPruneColor,
              ":hover": {
                background: colorsLight.accentPruneColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "royalFuchsia" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentRoyalFuchsiaColor,
              ":hover": {
                background: colorsLight.accentRoyalFuchsiaColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "rustyRed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentRustyRedColor,
              ":hover": {
                background: colorsLight.accentRustyRedColor
              }
            }
          }
        },
        // ghost
        {
          props: { variant: "ghost" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: "transparent",
              border: "1px solid " + colorsLight.accentRussianVioletColor,
              borderRadius: 4,
              padding: "4px 8px",
              height: 24,
              "& .MuiChip-label": {
                ...typographyTokens.captionTypography,
                color: colorsLight.accentRussianVioletColor,
                padding: 0
              },
              ":hover": {
                background: "transparent",
                "& .MuiChip-label": {
                  ...typographyTokens.captionTypography,
                  color: colorsLight.accentRussianVioletColor,
                  padding: 0
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "blueSapphire" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsLight.accentBlueSapphireColor,
              border: "1px solid " + colorsLight.accentBlueSapphireColor,
              "& .MuiChip-label": {
                color: colorsLight.accentBlueSapphireColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsLight.accentBlueSapphireColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "islamicGreen" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsLight.accentIslamicGreenColor,
              border: "1px solid " + colorsLight.accentIslamicGreenColor,
              "& .MuiChip-label": {
                color: colorsLight.accentIslamicGreenColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsLight.accentIslamicGreenColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "russianViolet" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsLight.accentRussianVioletColor,
              border: "1px solid " + colorsLight.accentRussianVioletColor,
              "& .MuiChip-label": {
                color: colorsLight.accentRussianVioletColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsLight.accentRussianVioletColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "maximumPurple" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsLight.accentMaximumPurpleColor,
              border: "1px solid " + colorsLight.accentMaximumPurpleColor,
              "& .MuiChip-label": {
                color: colorsLight.accentMaximumPurpleColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsLight.accentMaximumPurpleColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "mediumSlateBlue" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsLight.accentMediumSlateBlueColor,
              border: "1px solid " + colorsLight.accentMediumSlateBlueColor,
              "& .MuiChip-label": {
                color: colorsLight.accentMediumSlateBlueColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsLight.accentMediumSlateBlueColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "prune" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsLight.accentPruneColor,
              border: "1px solid " + colorsLight.accentPruneColor,
              "& .MuiChip-label": {
                color: colorsLight.accentPruneColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsLight.accentPruneColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "royalFuchsia" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsLight.accentRoyalFuchsiaColor,
              border: "1px solid " + colorsLight.accentRoyalFuchsiaColor,
              "& .MuiChip-label": {
                color: colorsLight.accentRoyalFuchsiaColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsLight.accentRoyalFuchsiaColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "rustyRed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsLight.accentRustyRedColor,
              border: "1px solid " + colorsLight.accentRustyRedColor,
              "& .MuiChip-label": {
                color: colorsLight.accentRustyRedColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsLight.accentRustyRedColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "completed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.baseSuccessColor,
              "& .MuiChip-label": {
                color: colorsLight.baseWhiteColor
              },
              ":hover": {
                background: colorsLight.baseSuccessColor,
                "& .MuiChip-label": {
                  color: colorsLight.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "draft" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.neutral50Color,
              "& .MuiChip-label": {
                color: colorsLight.baseWhiteColor
              },
              ":hover": {
                background: colorsLight.neutral50Color,
                "& .MuiChip-label": {
                  color: colorsLight.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "processing" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.baseInfoColor,
              "& .MuiChip-label": {
                color: colorsLight.baseWhiteColor
              },
              "& .MuiChip-icon": {
                color: colorsLight.baseWhiteColor,
                margin: 0,
                marginRight: 8,
                fontSize: 16
              },
              ":hover": {
                background: colorsLight.baseInfoColor,
                "& .MuiChip-icon": {
                  color: colorsLight.baseWhiteColor,
                  margin: 0,
                  marginRight: 8,
                  fontSize: 16
                },
                "& .MuiChip-label": {
                  color: colorsLight.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "confirmed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.baseWarningColor,
              "& .MuiChip-label": {
                color: colorsLight.textDefaultColor
              },
              ":hover": {
                background: colorsLight.baseWarningColor,
                "& .MuiChip-label": {
                  color: colorsLight.textDefaultColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "reversing" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.baseWarningColor,
              "& .MuiChip-label": {
                color: colorsLight.textDefaultColor
              },
              ":hover": {
                background: colorsLight.baseWarningColor,
                "& .MuiChip-label": {
                  color: colorsLight.textDefaultColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "reversed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsLight.accentMaximumPurpleColor,
              "& .MuiChip-label": {
                color: colorsLight.baseWhiteColor
              },
              ":hover": {
                background: colorsLight.accentMaximumPurpleColor,
                "& .MuiChip-label": {
                  color: colorsLight.baseWhiteColor
                }
              }
            }
          }
        }
      ]
    }
  }
});
export default lightTheme;
