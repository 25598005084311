import * as React from "react";
export { VaporThemeProvider as VaporThemeProvider } from "./VaporThemeProvider";

// To be removed in v1.0
import { VaporThemeProvider } from "./VaporThemeProvider";
export const ThemeProvider = (props: any) => {
  console.warn(
    `"ThemeProvider" is deprecated and will be removed in v1.0. Use "VaporThemeProvider" instead`
  );
  return <VaporThemeProvider {...props} />;
};
