import { createTheme } from "@mui/material/styles";
import tokenDataFic from "./tokens/tokens-fic.json";
import typography from "./tokens/typography-fic.json";
import { colorsToTokens, fontsToTokens } from "./helper";
import zIndex from "@mui/material/styles/zIndex";

const colorsFic = colorsToTokens(tokenDataFic);
const typographyTokens = fontsToTokens(typography);

const ficTheme = createTheme({
  name: "fic",
  palette: {
    primary: {
      main: colorsFic.textDefaultColor,
      textDisabledColor: colorsFic.textDisabledColor,
      textHintColor: colorsFic.textHintColor,
      background: colorsFic.backgroundDefaultColor,
      backgroudProminent: colorsFic.backgroundProminentColor,
      surfaceDefault: colorsFic.surfaceDefaultColor,
      borderDefault: colorsFic.borderDefaultColor,
      borderSuccessed: colorsFic.baseSuccessColor + " !important",
      borderError: colorsFic.borderCriticalColor + " !important",
      borderDisabled: colorsFic.borderDisabledColor + " !important",
      borderActive: colorsFic.borderActiveColor + " !important",
      successed: colorsFic.baseSuccessColor,
      textSubduedColor: colorsFic.textSubduedColor,
      error: colorsFic.textCriticalColor,
      iconDefault: colorsFic.iconDefaultColor,
      iconDisabled: colorsFic.iconDisabledColor,
      iconInverse: colorsFic.iconInverseColor,
      interactiveDefault: colorsFic.interactiveDefaultColor,
      interactiveSelectedColor: colorsFic.interactiveSelectedColor,
      interactiveDisabledColor: colorsFic.interactiveDisabledColor,
      inputBackgroundDefault: colorsFic.inputBackgroundDefaultColor,
      inputBackgroundActive: colorsFic.inputBackgroundActiveColor,
      inputBackgroundCritical: colorsFic.inputBackgroundCriticalColor,
      baseVaporGradient: colorsFic.baseVaporGradientColor,
      tertiaryTextDefault: colorsFic.tertiaryTextDefaultColor,
      tertiaryTextHoverColor: colorsFic.tertiaryTextHoverColor,
      tertiaryBackgroundActiveColor: colorsFic.tertiaryBackgroundActiveColor,
      primaryBackgroundHover: colorsFic.primaryBackgroundHoverColor,
      primary90Color: colorsFic.primary90Color,
      primary80Color: colorsFic.primary80Color,
      primary60Color: colorsFic.primary60Color,
      neutral50Color: colorsFic.neutral50Color,
      neutral10Color: colorsFic.neutral10Color,
      baseWhiteColor: colorsFic.baseWhiteColor,
      baseInfoColor: colorsFic.baseInfoColor,
      baseWarningColor: colorsFic.baseWarningColor,
      baseCriticalColor: colorsFic.baseCriticalColor,
      textTitleColor: colorsFic.textTitleColor,
      primaryTextDefaultColor: colorsFic.primaryTextDefaultColor,
      primary: colorsFic.primary60Color,
      primaryBackgroundDisabled: colorsFic.primaryBackgroundDisabledColor,
      primaryBackgroundDefault: colorsFic.primaryBackgroundDefaultColor,
      inputBackgroundDisabledColor: colorsFic.inputBackgroundDisabledColor,
      inputBackgroundReadonlyColor: colorsFic.inputBackgroundReadonlyColor,
      interactiveDefaultColor: colorsFic.interactiveDefaultColor,
      iconInfoColor: colorsFic.iconInfoColor,
      iconPrimaryColor: colorsFic.primary60Color,
      accentBlueSapphire: colorsFic.accentBlueSapphireColor, // new colors
      accentIslamicGreen: colorsFic.accentIslamicGreenColor,
      accentMaximumPurple: colorsFic.accentMaximumPurpleColor,
      accentMediumSlateBlue: colorsFic.accentMediumSlateBlueColor,
      accentOgreOdor: colorsFic.accentOgreOdorColor,
      accentPrune: colorsFic.accentPruneColor,
      accentRoyalFuchsia: colorsFic.accentRoyalFuchsiaColor,
      accentRussianViolet: colorsFic.accentRussianVioletColor,
      accentRustyRed: colorsFic.accentRustyRedColor,
      backgroundTooltip: colorsFic.backgroundTooltipColor,
      baseTransparent: colorsFic.baseTransparentColor,
      borderHover: colorsFic.borderHoverColor,
      borderInfo: colorsFic.borderInfoColor,
      borderSuccess: colorsFic.borderSuccessColor,
      borderWarning: colorsFic.borderWarningColor,
      criticalBackgroundActive: colorsFic.criticalBackgroundActiveColor,
      criticalBackgroundDefault: colorsFic.criticalBackgroundDefaultColor,
      criticalBackgroundDisabled: colorsFic.criticalBackgroundDisabledColor,
      criticalBackgroundHover: colorsFic.criticalBackgroundHoverColor,
      iconActive: colorsFic.iconActiveColor,
      iconCritical: colorsFic.iconCriticalColor,
      iconHover: colorsFic.iconHoverColor,
      iconSuccess: colorsFic.iconSuccessColor,
      iconWarning: colorsFic.iconWarningColor,
      inputBackgroundHover: colorsFic.inputBackgroundHoverColor,
      interactiveActive: colorsFic.interactiveActiveColor,
      interactiveFocused: colorsFic.interactiveFocusedColor,
      interactiveHover: colorsFic.interactiveHoverColor,
      neutral3: colorsFic.neutral3Color,
      neutral5: colorsFic.neutral5Color,
      neutral20: colorsFic.neutral20Color,
      neutral30: colorsFic.neutral30Color,
      neutral40: colorsFic.neutral40Color,
      neutral60: colorsFic.neutral60Color,
      neutral70: colorsFic.neutral70Color,
      neutral80: colorsFic.neutral80Color,
      neutral90: colorsFic.neutral90Color,
      neutral100: colorsFic.neutral100Color,
      primary5: colorsFic.primary5Color,
      primary10: colorsFic.primary10Color,
      primary20: colorsFic.primary20Color,
      primary30: colorsFic.primary30Color,
      primary40: colorsFic.primary40Color,
      primary50: colorsFic.primary50Color,
      primary70: colorsFic.primary70Color,
      primaryBackgroundActive: colorsFic.primaryBackgroundActiveColor,
      primaryTextActive: colorsFic.primaryTextActiveColor,
      primaryTextHover: colorsFic.primaryTextHoverColor,
      secondaryBackgroundActive: colorsFic.secondaryBackgroundActiveColor,
      secondaryBackgroundDefault: colorsFic.secondaryBackgroundDefaultColor,
      secondaryBackgroundDisabled: colorsFic.secondaryBackgroundDisabledColor,
      secondaryBackgroundHover: colorsFic.secondaryBackgroundHoverColor,
      secondaryBorderActive: colorsFic.secondaryBorderActiveColor,
      secondaryBorderDefault: colorsFic.secondaryBorderDefaultColor,
      secondaryBorderDisabled: colorsFic.secondaryBorderDisabledColor,
      secondaryBorderHover: colorsFic.secondaryBorderHoverColor,
      secondaryTextActive: colorsFic.secondaryTextActiveColor,
      secondaryTextDefault: colorsFic.secondaryTextDefaultColor,
      secondaryTextDisabled: colorsFic.secondaryTextDisabledColor,
      secondaryTextHover: colorsFic.secondaryTextHoverColor,
      surfaceActive: colorsFic.surfaceActiveColor,
      surfaceDisabled: colorsFic.surfaceDisabledColor,
      surfaceHover: colorsFic.surfaceHoverColor,
      tertiaryBackgroundDefault: colorsFic.tertiaryBackgroundDefaultColor,
      tertiaryBackgroundDisabled: colorsFic.tertiaryBackgroundDisabledColor,
      tertiaryBackgroundHover: colorsFic.tertiaryBackgroundHoverColor,
      tertiaryTextActive: colorsFic.tertiaryTextActiveColor,
      tertiaryTextDisabled: colorsFic.tertiaryTextDisabledColor,
      textInfo: colorsFic.textInfoColor,
      textInverse: colorsFic.textInverseColor,
      textOnProminent: colorsFic.textOnProminentColor,
      textSuccess: colorsFic.textSuccessColor,
      textWarning: colorsFic.textWarningColor,
      inputBackgroundCritical: colorsFic.inputBackgroundCriticalColor,
      // ---- abstract colors ----
      richElectricBlue: colorsFic.abstractRichElectricBlueColor,
      cyanProgress: colorsFic.abstractCyanProgressColor,
      darkImperialBlue: colorsFic.abstractDarkImperialBlueColor,
      columbiaBlue: colorsFic.abstractColumbiaBlueColor,
      azure: colorsFic.abstractAzureColor,
      diamond: colorsFic.abstractDiamondColor,
      white: colorsFic.abstractWhiteColor,
      whiteSmoke: colorsFic.abstractWhiteSmokeColor,
      azureishWhite: colorsFic.abstractAzureishWhiteColor,
      yankeesBlue: colorsFic.abstractYankeesBlueColor,
      cadet: colorsFic.abstractCadetColor,
      quickSilver: colorsFic.abstractQuickSilverColor,
      silverSand: colorsFic.abstractSilverSandColor,
      gainsboro: colorsFic.abstractGainsboroColor,
      ghostWhite: colorsFic.abstractGhostWhiteColor,
      laSalleGreen: colorsFic.abstractLaSalleGreenColor,
      crayola: colorsFic.abstractCrayolaColor,
      maximumRed: colorsFic.abstractMaximumRedColor
    },
    // appBar: { main: colorsFic.primary90Color },
    secondary: {
      main: colorsFic.interactiveDefaultColor
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontFamily: ["Roboto", "Cairo", "Inter"].join(","),
    titleMedium: {
      ...typographyTokens.titleMediumTypography,
      color: colorsFic.textTitleColor
    },
    titleSmall: {
      ...typographyTokens.titleSmallTypography,
      color: colorsFic.textTitleColor
    },
    titleXSmall: {
      ...typographyTokens.titleSmallTypography,
      color: colorsFic.textTitleColor,
      fontSize: 16
    },
    titleLarge: {
      ...typographyTokens.titleLargeTypography,
      color: colorsFic.textTitleColor
    },
    bodySmall: {
      ...typographyTokens.bodySmallTypography,
      color: colorsFic.textDefaultColor
    },
    body: {
      ...typographyTokens.bodyTypography,
      color: colorsFic.textDefaultColor
    },
    captionSmall: {
      ...typographyTokens.captionSmallTypography,
      color: colorsFic.textDefaultColor
    },
    caption: {
      ...typographyTokens.captionTypography,
      color: colorsFic.textDefaultColor
    },
    display: {
      ...typographyTokens.displayTypography,
      color: colorsFic.textTitleColor
    },
    eyebrow: {
      ...typographyTokens.eyebrowTypography,
      color: colorsFic.textDefaultColor
    },
    heading: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.headingTypography
    },
    pageDescription: {
      fontFamily: "Cairo",
      color: colorsFic.textSubduedColor,
      fontWeight: "400",
      fontSize: "12px"
    },
    label: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    boldLabel: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    labelDisabled: {
      color: colorsFic.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    formLabelDisabled: {
      color: colorsFic.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "400"
    },
    filedText: {
      color: colorsFic.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "400"
    },
    leadXLarge: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.leadXLargeTypography
    },
    leadLarge: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.leadLargeTypography
    },
    leadMedium: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.leadMediumTypography
    },
    leadSmall: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.leadSmallTypography
    },
    displayLarge: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.displayLargeTypography
    },
    displayMedium: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.displayMediumTypography
    },
    displaySmall: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.displaySmallTypography
    },
    subtitle: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.subtitleTypography
    },
    body500: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.body500Typography
    },
    body700: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.body700Typography
    },
    bodySmall500: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.bodySmall500Typography
    },
    bodySmall700: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.bodySmall700Typography
    },
    bodyLarge: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.bodyLargeTypography
    },
    bodyLarge500: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.bodyLarge500Typography
    },
    bodyLarge700: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.bodyLarge700Typography
    },
    buttonLarge: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.buttonLargeTypography
    },
    button: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.buttonTypography
    },
    codeInput: {
      color: colorsFic.textDefaultColor,
      ...typographyTokens.codeInputTypography
    }
  },
  spacing: 8,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          "&.MuiBackdrop-root.MuiBackdrop-root": {
            background: "rgba(51, 51, 51, 0.48)",
            opacity: 0.5 + "!important"
          },
          "&.MuiBackdrop-root.MuiBackdrop-invisible": {
            background: "transparent"
          }
        }
      }
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: "alert" },
          style: {
            "&.MuiDivider-root": {
              background: "transparent",
              border: "none",
              height: 0
            }
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          lineHeight: "16px",
          boxShadow: "none",
          ...typographyTokens.buttonTypography
          //   fontFamily: "Inter"
        }
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: colorsFic.primaryTextDefaultColor,
            background: colorsFic.primaryBackgroundDefaultColor,
            borderRadius: 4,
            "&.MuiButton-root.MuiButton-contained.Mui-disabled ": {
              background: colorsFic.primaryBackgroundDisabledColor,
              color: colorsFic.primaryTextDisabledColor,
              textShadow: "none"
            },
            "&:hover,&:focus": {
              background: colorsFic.primaryBackgroundHoverColor,
              color: colorsFic.primaryTextHoverColor,
              boxShadow: "none"
            },
            "&:active": {
              background: colorsFic.primaryBackgroundActiveColor,
              color: colorsFic.primaryTextActiveColor,
              boxShadow: "none"
            },
            "&.MuiButton-containedError": {
              background: colorsFic.criticalBackgroundDefaultColor,
              "&:hover,&:focus": {
                background: colorsFic.criticalBackgroundHoverColor,
                color: colorsFic.primaryTextHoverColor
              },
              "&.MuiButton-root.MuiButton-contained.Mui-disabled ": {
                background: colorsFic.criticalBackgroundDisabledColor,
                color: colorsFic.primaryTextDisabledColor,
                textShadow: "none"
              },
              "&:active": {
                background: colorsFic.criticalBackgroundActiveColor
              }
            }
          }
        },
        {
          props: { variant: "outlined" },
          style: {
            color: colorsFic.secondaryTextDefaultColor,
            border: "1px solid " + colorsFic.secondaryBorderDefaultColor,
            borderRadius: 4,
            "&:hover,&:focus": {
              border: "1px solid " + colorsFic.secondaryBorderHoverColor,
              color: colorsFic.secondaryTextHoverColor,
              background: colorsFic.secondaryBackgroundHoverColor
            },
            "&:active": {
              border: "1px solid " + colorsFic.secondaryBorderActiveColor,
              color: colorsFic.secondaryTextActiveColor,
              background: colorsFic.secondaryBackgroundActiveColor
            },
            "&.MuiButton-root.MuiButton-outlined.Mui-disabled ": {
              border:
                "1px solid " +
                colorsFic.secondaryBorderDisabledColor +
                " !important",
              color: colorsFic.secondaryTextDisabledColor,
              background: colorsFic.secondaryBackgroundDisabledColor,
              textShadow: "none"
            },
            "&.MuiButton-outlinedError": {
              color: colorsFic.textCriticalColor,
              border: "1px solid " + colorsFic.criticalL3Color + " !important",
              background: colorsFic.criticalL1Color,
              "&:hover,&:hover,&:focus": {
                border: "1px solid " + colorsFic.criticalL3Color,
                color: colorsFic.neutral100Color,
                background: colorsFic.criticalL2Color
              },
              "&:active": {
                border: "1px solid " + colorsFic.criticalL3Color,
                color: colorsFic.neutral100Color,
                background: colorsFic.criticalL3Color
              },
              "&.MuiButton-root.MuiButton-outlined.Mui-disabled ": {
                border:
                  "1px solid " +
                  colorsFic.secondaryBorderDisabledColor +
                  " !important",
                color: colorsFic.secondaryTextDisabledColor,
                background: colorsFic.secondaryBackgroundDisabled,
                textShadow: "none"
              }
            }
          }
        },
        {
          props: { variant: "text" },
          style: {
            color: colorsFic.tertiaryTextDefaultColor,
            background: colorsFic.tertiaryBackgroundDefaultColor,
            border: "transparent",
            borderRadius: 4,
            "&:hover,&:focus": {
              borderRadius: 4,
              background: colorsFic.tertiaryBackgroundHoverColor,
              border: "transparent",
              color: colorsFic.tertiaryTextHoverColor
            },
            "&:active": {
              borderRadius: 4,
              background: colorsFic.tertiaryBackgroundActiveColor,
              border: "transparent",
              color: colorsFic.tertiaryTextActiveColor
            },
            "&.MuiButton-root.MuiButton-text.Mui-disabled ": {
              color: colorsFic.tertiaryTextDisabledColor,
              background: colorsFic.tertiaryBackgroundDisabledColor
            },
            "&.MuiButton-textError": {
              color: colorsFic.textCriticalColor,
              border: "transparent",
              "&:hover,&:hover,&:focus": {
                color: colorsFic.neutral100Color,
                background: colorsFic.criticalL2Color,
                border: "transparent"
              },
              "&:active": {
                borderRadius: 4,
                background: colorsFic.criticalL3Color,
                border: "transparent",
                color: colorsFic.neutral100Color
              },
              "&.MuiButton-root.MuiButton-text.Mui-disabled ": {
                color: colorsFic.tertiaryTextDisabledColor
              }
            }
          }
        }
      ]
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: "transparent",
          borderRadius: 4,
          "&.MuiIconButton-root.MuiIconButton-colorPrimary": {
            color: colorsFic.iconDefaultColor,
            background: colorsFic.tertiaryBackgroundDefaultColor,
            "&:hover,&:active,&:focus": {
              borderRadius: 4,
              background: colorsFic.tertiaryBackgroundHoverColor,
              border: "transparent",
              color: colorsFic.tertiaryTextHoverColor
            },
            "&.Mui-disabled ": {
              color: colorsFic.tertiaryTextDisabledColor
            }
          },
          "&.Mui-disabled ": {
            color: colorsFic.tertiaryTextDisabledColor
          },
          "&.MuiIconButton-root.MuiIconButton-colorError": {
            color: colorsFic.iconCriticalColor,
            border: "transparent",
            "&:hover,&:focus": {
              // border: "1px solid " + colorsFic.iconCriticalColor,
              color: colorsFic.iconCriticalColor,
              background: colorsFic.criticalL2Color,
              border: "transparent",
              borderRadius: 4
            },
            "&:active": {
              color: colorsFic.iconCriticalColor,
              background: colorsFic.criticalL3Color,
              border: "transparent",
              borderRadius: 4
            },
            "&.Mui-disabled ": {
              color: colorsFic.tertiaryTextDisabledColor
            }
          },
          "&.MuiIconButton-root.MuiIconButton-colorSecondary": {
            color: "white",
            border: "transparent",
            "&:hover": {
              color: "white",
              background: "transparent",
              border: "1px solid " + colorsFic.borderHoverColor
            },
            "&:focus": {
              color: "white",
              background: colorsFic.baseVaporGradientColor,
              border: "transparent"
            }
          },
          "&.MuiButtonBase-root.MuiIconButton-root.IconButton-root:focus": {
            color: colorsFic.baseCriticalColor,
            borderRadius: 0,
            "&:focus": {
              borderRadius: 0,
              color: colorsFic.baseCriticalColor
            }
          },
          "&.MuiIconButton-edgeEnd": {
            marginRight: "-8px"
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiFormLabel-root.MuiInputLabel-root": {
            ...typographyTokens.bodySmallTypography,
            color: colorsFic.textDefaultColor,
            // fontWeight: 500,
            margin: 0,
            marginBottom: "8px",
            position: "relative",
            transform: "unset",
            WebkitTransform: "unset",
            "&.Mui-disabled": {
              color: colorsFic.textDisabledColor
            }
          }
        }
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            "&.MuiFormLabel-root.MuiInputLabel-root": {
              marginBottom: 6
            }
          }
        }
      ]
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.MuiFormHelperText-root": {
            ...typographyTokens.captionTypography,
            color: colorsFic.textSubduedColor,
            marginLeft: 0,
            marginRight: 0,
            marginTop: "8px",
            "&.Mui-error": {
              color: colorsFic.textCriticalColor
            },
            "&.Mui-disabled": {
              color: colorsFic.textDisabledColor
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ...typographyTokens.bodyTypography,
          // minHeight: 40,
          borderRadius: 4,
          padding: 0,
          background: colorsFic.bodyTypography,
          color: colorsFic.textDefaultColor,
          "& legend": {
            display: "none"
          },
          "& fieldset": { top: 0 },
          "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active,input:-internal-autofill-selected":
            {
              backgroundColor: "red",
              " -webkit-box-shadow":
                "0 0 0 30px " +
                colorsFic.inputBackgroundDefaultColor +
                " inset !important"
            },
          "fieldset ": {
            borderColor: colorsFic.borderDefaultColor
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid",
            borderColor: colorsFic.borderDefaultColor,
            "fieldset ": {
              borderColor: colorsFic.borderDefaultColor
            }
          },
          "& .MuiFormLabel-root.MuiInputLabel-root": {
            margin: 0,
            ...typographyTokens.bodySmallTypography,
            color: colorsFic.textDefaultColor,
            // fontWeight: 500,
            marginBottom: 8,
            position: "relative !important"
          },

          "&.Mui-focused": {
            background: colorsFic.inputBackgroundActiveColor
          },

          "&.MuiOutlinedInput-root": {
            "&:hover fieldset ": {
              border: "1px solid " + colorsFic.borderHoverColor, //set color border input hover
              "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline":
                {
                  // borderColor: colorsFic.borderCriticalColor,
                  border: "1px solid " + colorsFic.borderHoverColor
                }
            },

            "&.Mui-focused fieldset": {
              border: "2px solid " + colorsFic.borderActiveColor
            }
          },

          "&.Mui-disabled": {
            backgroundColor:
              colorsFic.inputBackgroundDisabledColor + " !important",
            fieldset: {
              border:
                "1px solid " + colorsFic.borderDisabledColor + " !important"
            },
            "&:hover fieldset ": {
              border:
                "1px solid " + colorsFic.borderDisabledColor + " !important",
              cursor: "not-allowed",
              pointerEvents: "all !important"
            }
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: colorsFic.textDisabledColor + " !important"
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            borderRadius: 0,
            padding: "10px 8px",
            height: "20px",
            "&:read-only": {
              backgroundColor:
                colorsFic.inputBackgroundDisabledColor + " !important"
            },
            "&:hover": {
              cursor: "auto",
              "&:disabled": {
                cursor: "not-allowed",
                pointerEvents: "all !important"
              }
            }
          }
        }
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            minHeight: 32,
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              borderRadius: 0,
              padding: "6px 8px",
              height: "20px"
            },
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              marginBottom: 4
            }
          }
        },
        {
          props: { error: true },
          style: {
            "&.MuiOutlinedInput-root": {
              fieldset: {
                border: "1px solid " + colorsFic.borderCriticalColor
              },
              "&:hover fieldset ": {
                border: "1px solid " + colorsFic.borderCriticalColor, //set color border input hover
                "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid " + colorsFic.borderCriticalColor
                  }
              },
              "&.Mui-focused fieldset": {
                border: "2px solid " + colorsFic.borderCriticalColor
              }
            }
          }
        }
      ]
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            color: colorsFic.textDefaultColor,
            ...typographyTokens.bodyTypography
          },
          "& .Mui-disabled": {
            color: colorsFic.textDisabledColor
          },

          "& .MuiFormControlLabel-label.Mui-disabled": {
            color: colorsFic.textDisabledColor
          }
        }
      }
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root.MuiIconButton-root": {
            color: colorsFic.iconDefaultColor,
            "&.Mui-disabled": {
              color: colorsFic.iconDisabledColor
            }
          },
          "&.MuiPickersDay-root": {
            color: colorsFic.textDefaultColor,
            ...typographyTokens.bodyTypography,
            background: "transparent",
            border: "none"
          },
          "&.MuiPickersDay-root.MuiPickersDay-today": {
            background: colorsFic.interactiveSelectedColor,
            color: colorsFic.interactiveDefaultColor,
            ...typographyTokens.bodyTypography,
            fontWeight: 700,
            border: "none"
          },
          "&.MuiPickersDay-root.Mui-selected": {
            background: colorsFic.baseVaporGradientColor,
            borderRadius: "50%",
            color: colorsFic.baseWhiteColor,
            ...typographyTokens.bodyTypography,
            fontWeight: 700,
            border: "none",
            "&:hover,&:focus": {
              backgroundColor: colorsFic.baseVaporGradientColor
            }
          },
          "&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall":
            {
              color: colorsFic.tertiaryTextDefaultColor
            },
          "&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall":
            {
              color: colorsFic.tertiaryTextDefaultColor
            },
          "&.MuiButtonBase-root.MuiMenuItem-root.MenuItem-root.Mui-selected": {
            background: colorsFic.interactiveSelectedColor
          },
          "&.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root":
            {
              background: colorsFic.interactiveSelectedColor,
              fontWeight: "400 !important"
            },
          "&.MuiButtonBase-root.MuiMenuItem-root": {
            ...typographyTokens.bodyTypography,
            border: "1px solid transparent"
          },
          "&.MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible": {
            background: colorsFic.interactiveSelectedColor
          },
          "&.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.MuiButtonBase-root":
            {
              color: colorsFic.textDefaultColor
            }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          "&.MuiListSubheader-root.MuiListSubheader-gutters.MuiListSubheader-sticky":
            {
              color: colorsFic.textDefaultColor
            }
        }
      }
    },

    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          "&.MuiDateRangePickerDay-rangeIntervalDayHighlight": {
            background: colorsFic.interactiveSelectedColor
          },
          "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            color: colorsFic.textDefaultColor,
            background: colorsFic.surfaceDefaultColor,
            "& .MuiSvgIcon-root": {
              color: colorsFic.iconDefaultColor
            }
          }
        }
      }
    },

    MuiDateCalendar: {
      styleOverrides: {
        root: {
          ...typographyTokens.bodyTypography,
          color: colorsFic.textDefaultColor,
          background: colorsFic.surfaceDefaultColor,
          borderRadius: "4px",
          "& .MuiSvgIcon-root": {
            color: colorsFic.iconDefaultColor
          },
          "& .MuiTypography-root.MuiTypography-caption": {
            ...typographyTokens.eyebrowTypography,
            color: colorsFic.textDisabledColor
          }
        }
      }
    },

    MuiYearCalendar: {
      styleOverrides: {
        root: {
          ...typographyTokens.bodyTypography,
          "& .PrivatePickersYear-root button": {
            color: colorsFic.textDefaultColor
          },
          "& .PrivatePickersYear-root .Mui-selected": {
            background: colorsFic.baseVaporGradientColor
          },
          "& .MuiPickersYear-yearButton.Mui-selected": {
            background: colorsFic.baseVaporGradientColor,
            "&:hover,&:focus": {
              background: colorsFic.baseVaporGradientColor
            }
          }
        }
      }
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: colorsFic.interactiveDefaultColor,
          ...typographyTokens.bodyTypography,
          "&.MuiLink-underlineAlways": {
            textDecoration: "underline",
            textDecorationColor: colorsFic.interactiveDefaultColor
          }
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
            ...typographyTokens.bodyTypography,
            color: colorsFic.textDefaultColor,
            background: colorsFic.surfaceDefaultColor
          },
          "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            color: colorsFic.textDefaultColor,
            background: colorsFic.surfaceDefaultColor,
            borderRadius: 4,
            border: "1px solid " + colorsFic.borderDefaultColor,
            boxShadow: "0px 10px 20px 0px #15293526",
            marginTop: "2px"
          },
          "&.MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
            marginTop: "2px"
          },
          "&.MuiPaper-root.MuiAutocomplete-paper": {
            marginTop: "2px"
          }
        }
      },
      variants: [
        {
          props: { variant: "menuItem" },
          style: {
            "&.MuiPaper-root.MuiPaper-menuItem.MuiPopover-paper": {
              top: "48px !important",
              left: "48px !important",
              width: 200,
              height: "100%",
              background: colorsFic.surfaceDefaultColor,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        }
      ]
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
          background: colorsFic.backgroundDefaultColor
        }
      },
      variants: [
        {
          props: { variant: "navigation" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-root": {
              width: "inherit",
              position: "fixed",
              background: colorsFic.primary90Color
            }
          }
        },
        {
          props: { variant: "regular" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "small" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 48,
              height: 48,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "medium" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "large" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 72,
              height: 72,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "surface" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56
            }
          }
        },
        {
          props: { variant: "surface", size: "small" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 48,
              height: 48
            }
          }
        },
        {
          props: { variant: "surface", size: "medium" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56
            }
          }
        },
        {
          props: { variant: "surface", size: "large" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 72,
              height: 72
            }
          }
        }
      ]
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 2,
          backgroundColor: colorsFic.ficTooltipColor,
          ...typographyTokens.captionTypography,
          color: colorsFic.textInverseColor
        },
        arrow: {
          "&::before": {
            backgroundColor: colorsFic.backgroundProminentColor
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "&.MuiInputAdornment-root": {
            marginLeft: 8,
            marginRight: 8,
            "& .MuiTypography-root": {
              ...typographyTokens.bodyTypography,
              color: colorsFic.textDefaultColor
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colorsFic.borderDefaultColor,
          "&.MuiCheckbox-root.Mui-checked": {
            color: colorsFic.interactiveDefaultColor
          },
          "&.MuiCheckbox-root.Mui-disabled": {
            color: colorsFic.interactiveDisabledColor
          },
          "&.MuiCheckbox-root.Mui-disabled.Mui-checked": {
            color: colorsFic.primary10Color
          },
          "&.MuiCheckbox-root.MuiCheckbox-indeterminate": {
            color: colorsFic.interactiveDefaultColor
          },
          "&.MuiCheckbox-root.MuiCheckbox-indeterminate.Mui-disabled.Mui-checked":
            {
              color: colorsFic.primary10Color
            }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colorsFic.textDefaultColor
        },
        root: {
          zIndex: 4
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: zIndex.drawer,
          "& .MuiListItemIcon-root": {
            color: "#fff"
          },
          "& .MuiListItemText-root": {
            color: "#fff"
          }
        },
        paper: {
          backgroundColor: colorsFic.textDefaultColor
        }
      }
    },
    MuiAccordion: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            "&.MuiAccordion-root": {
              border: "1px solid " + colorsFic.borderCriticalColor
            },
            "&.MuiAccordion-root .MuiAccordionSummary-content": {
              marginRight: 0
            },
            "&.MuiAccordion-root .MuiAccordionSummary-root .error-icon": {
              display: "block"
            }
          }
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: "14px",
          color: colorsFic.textDefaultColor
        },
        option: {
          "&:hover": {
            backgroundColor: colorsFic.surfaceHoverColor + " !important"
          },
          '&[aria-selected="true"]': {
            backgroundColor: colorsFic.interactiveSelectedColor + " !important"
          },
          "& .MuiAutocomplete-option.MuiFocused": {
            backgroundColor: colorsFic.surfaceHoverColor + " !important"
          },
          border: "1px solid transparent"
        },
        root: {
          "& .MuiAutocomplete-option.MuiFocused": {
            backgroundColor: colorsFic.surfaceHoverColor + " !important"
          }
        }
      }
    },
    MuiTabs: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 72
            }
          }
        },
        {
          props: { orientation: "vertical" },
          style: {
            "&.MuiTabs-root.MuiTabs-vertical": {
              "& .MuiTab-root:hover": {
                background: colorsFic.interactiveSelectedColor
              }
            }
          }
        },
        {
          props: { variant: "sticky", orientation: "vertical" },
          style: {
            "&.MuiTabs-root.MuiTabs-vertical": {
              right: "0 !important",
              "& .MuiTab-root:hover": {
                background: colorsFic.interactiveSelectedColor
              }
            }
          }
        },
        {
          props: { variant: "standard", size: "medium" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 72
            }
          }
        },
        {
          props: { variant: "standard", size: "small" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              "& .MuiTab-root": {
                fontSize: 14
              }
            }
          }
        },
        {
          props: { variant: "scrollable", size: "small" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              "& .MuiTab-root": {
                fontSize: 14
              }
            }
          }
        },
        {
          props: { variant: "secondary" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator": {
              height: 0
            },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              alignItems: "center"
            },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root":
              {
                padding: "4px 12px",
                margin: "0 12px",
                fontSize: 14,
                fontFamily: "Cairo",
                fontWeight: 600,
                color: colorsFic.textSubduedColor,
                ":hover": {
                  minHeight: 32,
                  height: 32,
                  background: colorsFic.interactiveDefaultColor,
                  color: colorsFic.baseWhiteColor,
                  padding: "4px 12px",
                  borderBottom: "0 !important",
                  borderRadius: 2
                }
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-selected":
              {
                minHeight: 32,
                height: 32,
                background: "#B9E6F6",
                color: colorsFic.textTitleColor,
                padding: "4px 12px",
                borderBottom:
                  "1px solid " +
                  colorsFic.interactiveDefaultColor +
                  " !important",
                border: "1px solid " + colorsFic.interactiveDefaultColor,
                borderRadius: 2
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-disabled":
              {
                margin: "0 12px",
                color: "#D8D8D9",
                border: "none",
                background: "transparent"
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-disabled:hover":
              {
                background: "transparent",
                borderBottom: "0 !important"
              }
          }
        }
      ]
    },

    MuiTab: {
      variants: [
        {
          props: { variant: "activeStep" },
          style: {
            "&.MuiTab-root	": {
              borderBottomWidth: 106,
              paddingRight: "48px !important",
              clipPath:
                "polygon(0% 0%, calc(100% - 24px) 0%, 100% 50%, calc(100% - 24px) 100%, 0% 100%)"
            }
          }
        }
      ]
    },

    MuiChip: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentRussianVioletColor,
              borderRadius: 4,
              padding: "4px 8px",
              height: 24,
              "& .MuiChip-label": {
                ...typographyTokens.captionTypography,
                color: colorsFic.baseWhiteColor,
                padding: 0
              },
              ":hover": {
                background: colorsFic.accentRussianVioletColor,
                "& .MuiChip-label": {
                  ...typographyTokens.captionTypography,
                  color: colorsFic.baseWhiteColor,
                  padding: 0
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "blueSapphire" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentBlueSapphireColor,
              ":hover": {
                background: colorsFic.accentBlueSapphireColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "islamicGreen" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentIslamicGreenColor,
              ":hover": {
                background: colorsFic.accentIslamicGreenColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "russianViolet" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentRussianVioletColor,
              ":hover": {
                background: colorsFic.accentRussianVioletColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "maximumPurple" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentMaximumPurpleColor,
              ":hover": {
                background: colorsFic.accentMaximumPurpleColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "mediumSlateBlue" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentMediumSlateBlueColor,
              ":hover": {
                background: colorsFic.accentMediumSlateBlueColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "prune" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentPruneColor,
              ":hover": {
                background: colorsFic.accentPruneColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "royalFuchsia" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentRoyalFuchsiaColor,
              ":hover": {
                background: colorsFic.accentRoyalFuchsiaColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "rustyRed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentRustyRedColor,
              ":hover": {
                background: colorsFic.accentRustyRedColor
              }
            }
          }
        },
        // ghost
        {
          props: { variant: "ghost" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: "transparent",
              border: "1px solid " + colorsFic.accentRussianVioletColor,
              borderRadius: 4,
              padding: "4px 8px",
              height: 24,
              "& .MuiChip-label": {
                ...typographyTokens.captionTypography,
                color: colorsFic.accentRussianVioletColor,
                padding: 0
              },
              ":hover": {
                background: "transparent",
                "& .MuiChip-label": {
                  ...typographyTokens.captionTypography,
                  color: colorsFic.accentRussianVioletColor,
                  padding: 0
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "blueSapphire" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsFic.accentBlueSapphireColor,
              border: "1px solid " + colorsFic.accentBlueSapphireColor,
              "& .MuiChip-label": {
                color: colorsFic.accentBlueSapphireColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsFic.accentBlueSapphireColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "islamicGreen" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsFic.accentIslamicGreenColor,
              border: "1px solid " + colorsFic.accentIslamicGreenColor,
              "& .MuiChip-label": {
                color: colorsFic.accentIslamicGreenColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsFic.accentIslamicGreenColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "russianViolet" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsFic.accentRussianVioletColor,
              border: "1px solid " + colorsFic.accentRussianVioletColor,
              "& .MuiChip-label": {
                color: colorsFic.accentRussianVioletColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsFic.accentRussianVioletColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "maximumPurple" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsFic.accentMaximumPurpleColor,
              border: "1px solid " + colorsFic.accentMaximumPurpleColor,
              "& .MuiChip-label": {
                color: colorsFic.accentMaximumPurpleColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsFic.accentMaximumPurpleColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "mediumSlateBlue" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsFic.accentMediumSlateBlueColor,
              border: "1px solid " + colorsFic.accentMediumSlateBlueColor,
              "& .MuiChip-label": {
                color: colorsFic.accentMediumSlateBlueColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsFic.accentMediumSlateBlueColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "prune" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsFic.accentPruneColor,
              border: "1px solid " + colorsFic.accentPruneColor,
              "& .MuiChip-label": {
                color: colorsFic.accentPruneColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsFic.accentPruneColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "royalFuchsia" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsFic.accentRoyalFuchsiaColor,
              border: "1px solid " + colorsFic.accentRoyalFuchsiaColor,
              "& .MuiChip-label": {
                color: colorsFic.accentRoyalFuchsiaColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsFic.accentRoyalFuchsiaColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "rustyRed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsFic.accentRustyRedColor,
              border: "1px solid " + colorsFic.accentRustyRedColor,
              "& .MuiChip-label": {
                color: colorsFic.accentRustyRedColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsFic.accentRustyRedColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "completed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.baseSuccessColor,
              "& .MuiChip-label": {
                color: colorsFic.baseWhiteColor
              },
              ":hover": {
                background: colorsFic.baseSuccessColor,
                "& .MuiChip-label": {
                  color: colorsFic.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "draft" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.neutral50Color,
              "& .MuiChip-label": {
                color: colorsFic.baseWhiteColor
              },
              ":hover": {
                background: colorsFic.neutral50Color,
                "& .MuiChip-label": {
                  color: colorsFic.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "processing" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.baseInfoColor,
              "& .MuiChip-label": {
                color: colorsFic.baseWhiteColor
              },
              "& .MuiChip-icon": {
                color: colorsFic.baseWhiteColor,
                margin: 0,
                marginRight: 8,
                fontSize: 16
              },
              ":hover": {
                background: colorsFic.baseInfoColor,
                "& .MuiChip-icon": {
                  color: colorsFic.baseWhiteColor,
                  margin: 0,
                  marginRight: 8,
                  fontSize: 16
                },
                "& .MuiChip-label": {
                  color: colorsFic.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "confirmed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.baseWarningColor,
              "& .MuiChip-label": {
                color: colorsFic.textDefaultColor
              },
              ":hover": {
                background: colorsFic.baseWarningColor,
                "& .MuiChip-label": {
                  color: colorsFic.textDefaultColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "reversing" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.baseWarningColor,
              "& .MuiChip-label": {
                color: colorsFic.textDefaultColor
              },
              ":hover": {
                background: colorsFic.baseWarningColor,
                "& .MuiChip-label": {
                  color: colorsFic.textDefaultColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "reversed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsFic.accentMaximumPurpleColor,
              "& .MuiChip-label": {
                color: colorsFic.baseWhiteColor
              },
              ":hover": {
                background: colorsFic.accentMaximumPurpleColor,
                "& .MuiChip-label": {
                  color: colorsFic.baseWhiteColor
                }
              }
            }
          }
        }
      ]
    },
    MuiSelect: {
      variants: [
        {
          props: { size: "small" },
          style: {
            "& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
              {
                textAlign: "left",
                paddingTop: 6,
                paddingBottom: 6,
                paddingRight: 30,
                paddingLeft: 8
              }
          }
        },
        {
          props: { error: true },
          style: {
            "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
              {
                background:
                  colorsFic.inputBackgroundCriticalColor + " !important",
                borderRadius: "4px"
              }
          }
        }
      ]
    }
  }
});
export default ficTheme;
