import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "../themes/light";
import darkTheme from "../themes/dark";
import ficTheme from "../themes/fic";

interface ThemeProps {
  isDark?: boolean;
  children?: React.ReactNode;
  name?: "vapor-light" | "vapor-dark" | "fic";
}

declare module "@mui/material/styles" {
  interface Theme {
    name?: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    name?: string;
  }
}

declare module "@mui/system" {
  interface Theme {
    name?: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    name?: string;
  }
}

export const VaporThemeProvider: React.FC<ThemeProps> = (props: ThemeProps) => {
  const { isDark = false, name = "vapor-light" } = props;
  return (
    <ThemeProvider
      theme={
        isDark
          ? darkTheme
          : name
          ? name === "vapor-dark"
            ? darkTheme
            : name === "fic"
            ? ficTheme
            : lightTheme
          : lightTheme
      }
    >
      {props.children}
    </ThemeProvider>
  );
};

export function withThemeFunction(StoryFn, context) {
  return React.createElement(
    VaporThemeProvider,
    {
      isDark: context.globals.theme === "vapor-dark",
      name: context.globals.theme
    },
    React.createElement(StoryFn, context)
  );
}
