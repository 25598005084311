import * as React from "react";
import { useTheme as useThemeSystem } from "@mui/system";
import lightTheme from "../themes/light";
import { Theme } from "@mui/system";

export default function useTheme<T = Theme>(): Theme {
  const theme = useThemeSystem(lightTheme);

  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useDebugValue(theme);
  }

  return theme;
}
