import { createTheme } from "@mui/material/styles";
import tokenDataDark from "./tokens/tokens-dark.json";
import typography from "./tokens/typography.json";
import { colorsToTokens, fontsToTokens } from "./helper";
import zIndex from "@mui/material/styles/zIndex";

const colorsDark = colorsToTokens(tokenDataDark);
const typographyTokens = fontsToTokens(typography);

const darkTheme = createTheme({
  name: "vapor-dark",
  palette: {
    primary: {
      main: colorsDark.textDefaultColor,
      textDisabledColor: colorsDark.textDisabledColor,
      textHintColor: colorsDark.textHintColor,
      background: colorsDark.backgroundDefaultColor,
      backgroudProminent: colorsDark.backgroundProminentColor,
      surfaceDefault: colorsDark.surfaceDefaultColor,
      borderDefault: colorsDark.borderDefaultColor,
      borderSuccessed: colorsDark.baseSuccessColor + " !important",
      borderError: colorsDark.borderCriticalColor + " !important",
      borderDisabled: colorsDark.borderDisabledColor + " !important",
      borderActive: colorsDark.borderActiveColor + " !important",
      successed: colorsDark.baseSuccessColor,
      textSubduedColor: colorsDark.textSubduedColor,
      error: colorsDark.textCriticalColor,
      iconDefault: colorsDark.iconDefaultColor,
      iconDisabled: colorsDark.iconDisabledColor,
      iconInverse: colorsDark.iconInverseColor,
      interactiveDefault: colorsDark.interactiveDefaultColor,
      interactiveSelectedColor: colorsDark.interactiveSelectedColor,
      interactiveDisabledColor: colorsDark.interactiveDisabledColor,
      inputBackgroundDefault: colorsDark.inputBackgroundDefaultColor,
      inputBackgroundActive: colorsDark.inputBackgroundActiveColor,
      baseVaporGradient: colorsDark.baseVaporGradientColor,
      tertiaryTextDefault: colorsDark.tertiaryTextDefaultColor,
      primaryBackgroundHover: colorsDark.primaryBackgroundHoverColor,
      primary90Color: colorsDark.primary90Color,
      primary80Color: colorsDark.primary80Color,
      primary60Color: colorsDark.primary60Color,
      neutral50Color: colorsDark.neutral50Color,
      neutral10Color: colorsDark.neutral10Color,
      baseWhiteColor: colorsDark.baseWhiteColor,
      baseInfoColor: colorsDark.baseInfoColor,
      baseWarningColor: colorsDark.baseWarningColor,
      baseCriticalColor: colorsDark.baseCriticalColor,
      textTitleColor: colorsDark.textTitleColor,
      primaryTextDefaultColor: colorsDark.primaryTextDefaultColor,
      primary: colorsDark.primary60Color,
      primaryBackgroundDisabled: colorsDark.primaryBackgroundDisabledColor,
      primaryBackgroundDefault: colorsDark.primaryBackgroundDefaultColor,
      inputBackgroundDisabledColor: colorsDark.inputBackgroundDisabledColor,
      inputBackgroundReadonlyColor: colorsDark.inputBackgroundReadonlyColor,
      interactiveDefaultColor: colorsDark.interactiveDefaultColor,
      iconInfoColor: colorsDark.iconInfoColor,
      iconPrimaryColor: colorsDark.primary30Color,
      accentBlueSapphire: colorsDark.accentBlueSapphireColor, // new colors
      accentIslamicGreen: colorsDark.accentIslamicGreenColor,
      accentMaximumPurple: colorsDark.accentMaximumPurpleColor,
      accentMediumSlateBlue: colorsDark.accentMediumSlateBlueColor,
      accentOgreOdor: colorsDark.accentOgreOdorColor,
      accentPrune: colorsDark.accentPruneColor,
      accentRoyalFuchsia: colorsDark.accentRoyalFuchsiaColor,
      accentRussianViolet: colorsDark.accentRussianVioletColor,
      accentRustyRed: colorsDark.accentRustyRedColor,
      backgroundTooltip: colorsDark.backgroundTooltipColor,
      baseTransparent: colorsDark.baseTransparentColor,
      borderHover: colorsDark.borderHoverColor,
      borderInfo: colorsDark.borderInfoColor,
      borderSuccess: colorsDark.borderSuccessColor,
      borderWarning: colorsDark.borderWarningColor,
      criticalBackgroundActive: colorsDark.criticalBackgroundActiveColor,
      criticalBackgroundDefault: colorsDark.criticalBackgroundDefaultColor,
      criticalBackgroundDisabled: colorsDark.criticalBackgroundDisabledColor,
      criticalBackgroundHover: colorsDark.criticalBackgroundHoverColor,
      iconActive: colorsDark.iconActiveColor,
      iconCritical: colorsDark.iconCriticalColor,
      iconHover: colorsDark.iconHoverColor,
      iconSuccess: colorsDark.iconSuccessColor,
      iconWarning: colorsDark.iconWarningColor,
      inputBackgroundHover: colorsDark.inputBackgroundHoverColor,
      interactiveActive: colorsDark.interactiveActiveColor,
      interactiveFocused: colorsDark.interactiveFocusedColor,
      interactiveHover: colorsDark.interactiveHoverColor,
      neutral3: colorsDark.neutral3Color,
      neutral5: colorsDark.neutral5Color,
      neutral20: colorsDark.neutral20Color,
      neutral30: colorsDark.neutral30Color,
      neutral40: colorsDark.neutral40Color,
      neutral60: colorsDark.neutral60Color,
      neutral70: colorsDark.neutral70Color,
      neutral80: colorsDark.neutral80Color,
      neutral90: colorsDark.neutral90Color,
      neutral100: colorsDark.neutral100Color,
      primary5: colorsDark.primary5Color,
      primary10: colorsDark.primary10Color,
      primary20: colorsDark.primary20Color,
      primary30: colorsDark.primary30Color,
      primary40: colorsDark.primary40Color,
      primary50: colorsDark.primary50Color,
      primary70: colorsDark.primary70Color,
      primaryBackgroundActive: colorsDark.primaryBackgroundActiveColor,
      primaryTextActive: colorsDark.primaryTextActiveColor,
      primaryTextHover: colorsDark.primaryTextHoverColor,
      secondaryBackgroundActive: colorsDark.secondaryBackgroundActiveColor,
      secondaryBackgroundDefault: colorsDark.secondaryBackgroundDefaultColor,
      secondaryBackgroundDisabled: colorsDark.secondaryBackgroundDisabledColor,
      secondaryBackgroundHover: colorsDark.secondaryBackgroundHoverColor,
      secondaryBorderActive: colorsDark.secondaryBorderActiveColor,
      secondaryBorderDefault: colorsDark.secondaryBorderDefaultColor,
      secondaryBorderDisabled: colorsDark.secondaryBorderDisabledColor,
      secondaryBorderHover: colorsDark.secondaryBorderHoverColor,
      secondaryTextActive: colorsDark.secondaryTextActiveColor,
      secondaryTextDefault: colorsDark.secondaryTextDefaultColor,
      secondaryTextDisabled: colorsDark.secondaryTextDisabledColor,
      secondaryTextHover: colorsDark.secondaryTextHoverColor,
      surfaceActive: colorsDark.surfaceActiveColor,
      surfaceDisabled: colorsDark.surfaceDisabledColor,
      surfaceHover: colorsDark.surfaceHoverColor,
      tertiaryBackgroundDefault: colorsDark.tertiaryBackgroundDefaultColor,
      tertiaryBackgroundDisabled: colorsDark.tertiaryBackgroundDisabledColor,
      tertiaryBackgroundHover: colorsDark.tertiaryBackgroundHoverColor,
      tertiaryTextActive: colorsDark.tertiaryTextActiveColor,
      tertiaryTextDisabled: colorsDark.tertiaryTextDisabledColor,
      textInfo: colorsDark.textInfoColor,
      textInverse: colorsDark.textInverseColor,
      textOnProminent: colorsDark.textOnProminentColor,
      textSuccess: colorsDark.textSuccessColor,
      textWarning: colorsDark.textWarningColor,
      // ---- abstract colors ----
      richElectricBlue: colorsDark.abstractRichElectricBlueColor,
      cyanProgress: colorsDark.abstractCyanProgressColor,
      darkImperialBlue: colorsDark.abstractDarkImperialBlueColor,
      columbiaBlue: colorsDark.abstractColumbiaBlueColor,
      azure: colorsDark.abstractAzureColor,
      diamond: colorsDark.abstractDiamondColor,
      white: colorsDark.abstractWhiteColor,
      whiteSmoke: colorsDark.abstractWhiteSmokeColor,
      azureishWhite: colorsDark.abstractAzureishWhiteColor,
      yankeesBlue: colorsDark.abstractYankeesBlueColor,
      cadet: colorsDark.abstractCadetColor,
      quickSilver: colorsDark.abstractQuickSilverColor,
      silverSand: colorsDark.abstractSilverSandColor,
      gainsboro: colorsDark.abstractGainsboroColor,
      ghostWhite: colorsDark.abstractGhostWhiteColor,
      laSalleGreen: colorsDark.abstractLaSalleGreenColor,
      crayola: colorsDark.abstractCrayolaColor,
      maximumRed: colorsDark.abstractMaximumRedColor
    },
    secondary: {
      main: colorsDark.interactiveDefaultColor
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontFamily: ["Roboto", "Cairo"].join(","),
    titleMedium: {
      ...typographyTokens.titleMediumTypography,
      color: colorsDark.textTitleColor
    },
    titleSmall: {
      ...typographyTokens.titleSmallTypography,
      color: colorsDark.textTitleColor
    },
    titleXSmall: {
      ...typographyTokens.titleSmallTypography,
      color: colorsDark.textTitleColor,
      fontSize: 16
    },
    titleLarge: {
      ...typographyTokens.titleLargeTypography,
      color: colorsDark.textTitleColor
    },
    bodySmall: {
      ...typographyTokens.bodySmallTypography,
      color: colorsDark.textDefaultColor
    },
    body: {
      ...typographyTokens.bodyTypography,
      color: colorsDark.textDefaultColor
    },
    captionSmall: {
      ...typographyTokens.captionSmallTypography,
      color: colorsDark.textDefaultColor
    },
    caption: {
      ...typographyTokens.captionTypography,
      color: colorsDark.textDefaultColor
    },
    display: {
      ...typographyTokens.displayTypography,
      color: colorsDark.textTitleColor
    },
    eyebrow: {
      ...typographyTokens.eyebrowTypography,
      color: colorsDark.textDefaultColor
    },
    heading: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.headingTypography
    },
    pageDescription: {
      fontFamily: "Cairo",
      color: colorsDark.textSubduedColor,
      fontWeight: "400",
      fontSize: "12px"
    },
    label: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    boldLabel: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    labelDisabled: {
      color: colorsDark.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "500"
    },
    formLabelDisabled: {
      color: colorsDark.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: 400
    },
    filedText: {
      color: colorsDark.textDisabledColor,
      ...typographyTokens.bodyTypography,
      fontWeight: "400"
    },
    leadXLarge: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.leadXLargeTypography
    },
    leadLarge: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.leadLargeTypography
    },
    leadMedium: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.leadMediumTypography
    },
    leadSmall: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.leadSmallTypography
    },
    displayLarge: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.displayLargeTypography
    },
    displayMedium: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.displayMediumTypography
    },
    displaySmall: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.displaySmallTypography
    },
    subtitle: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.subtitleTypography
    },
    body500: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.body500Typography
    },
    body700: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.body700Typography
    },
    bodySmall500: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.bodySmall500Typography
    },
    bodySmall700: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.bodySmall700Typography
    },
    bodyLarge: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.bodyLargeTypography
    },
    bodyLarge500: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.bodyLarge500Typography
    },
    bodyLarge700: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.bodyLarge700Typography
    },
    buttonLarge: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.buttonLargeTypography
    },
    button: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.buttonTypography
    },
    codeInput: {
      color: colorsDark.textDefaultColor,
      ...typographyTokens.codeInputTypography
    }
  },
  spacing: 8,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          "&.MuiBackdrop-root.MuiBackdrop-root": {
            background: colorsDark.surfaceDefaultColor,
            opacity: 0.5 + "!important"
          },
          "&.MuiBackdrop-root.MuiBackdrop-invisible": {
            background: "transparent"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          lineHeight: "16px",
          boxShadow: "none",
          ...typographyTokens.buttonTypography
        }
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: colorsDark.primaryTextDefaultColor,
            background: colorsDark.baseVaporGradientColor,
            textShadow: "1px 1px 0 rgba(21,41,53,0.24)",
            borderRadius: 2,
            "&.MuiButton-root.MuiButton-contained.Mui-disabled ": {
              background: colorsDark.primaryBackgroundDisabledColor,
              color: colorsDark.primaryTextDisabledColor,
              textShadow: "none"
            },
            "&:active,&:hover,&:focus": {
              background:
                "linear-gradient(140deg, " +
                colorsDark.primaryBackgroundHoverColor +
                " 100%, " +
                colorsDark.primaryBackgroundHoverColor +
                " 100%)",
              color: "colorsDark.primaryTextHoverColor",
              boxShadow: "none"
            },
            "&.MuiButton-containedError": {
              background: colorsDark.criticalBackgroundDefaultColor,
              "&:hover": {
                background: colorsDark.criticalBackgroundHoverColor,
                color: colorsDark.primaryTextHoverColor
              },
              "&.MuiButton-root.MuiButton-contained.Mui-disabled ": {
                background: colorsDark.criticalBackgroundDisabledColor,
                color: colorsDark.primaryTextDisabledColor,
                textShadow: "none"
              },
              "&:active,&:hover,&:focus": {
                background: colorsDark.criticalBackgroundActiveColor
              }
            }
          }
        },
        {
          props: { variant: "outlined" },
          style: {
            color: colorsDark.secondaryTextDefaultColor,
            border: "1px solid " + colorsDark.secondaryBorderDefaultColor,
            borderRadius: 2,
            "&:hover": {
              border: "1px solid " + colorsDark.secondaryBackgroundHoverColor,
              color: colorsDark.secondaryTextHoverColor,
              background: colorsDark.secondaryBackgroundHoverColor
            },
            "&:active,&:focus": {
              border: "1px solid " + colorsDark.secondaryBackgroundActiveColor,
              color: colorsDark.secondaryTextActiveColor,
              background: colorsDark.secondaryBackgroundActiveColor
            },
            "&.MuiButton-root.MuiButton-outlined.Mui-disabled ": {
              border:
                "1px solid " + colorsDark.borderDisabledColor + " !important",
              color: colorsDark.borderDisabledColor,
              textShadow: "none"
            },
            "&.MuiButton-outlinedError": {
              color: colorsDark.baseCriticalColor,
              border:
                "1px solid " + colorsDark.baseCriticalColor + "!important",
              "&:hover,&:hover,&:focus": {
                border: "1px solid " + colorsDark.baseCriticalColor,
                color: "white",
                background: colorsDark.baseCriticalColor
              },
              "&.MuiButton-root.MuiButton-outlined.Mui-disabled ": {
                border:
                  "1px solid " + colorsDark.borderDisabledColor + " !important",
                color: colorsDark.borderDisabledColor,
                textShadow: "none"
              }
            }
          }
        },
        {
          props: { variant: "text" },
          style: {
            color: colorsDark.tertiaryTextDefaultColor,
            background: colorsDark.tertiaryBackgroundDefaultColor,
            border: "transparent",
            borderRadius: 0,
            "&:hover,&:active,&:focus": {
              borderRadius: 0,
              background: colorsDark.tertiaryBackgroundHoverColor,
              border: "transparent",
              color: colorsDark.tertiaryTextHoverColor
            },
            "&.MuiButton-root.MuiButton-text.Mui-disabled ": {
              color: colorsDark.tertiaryTextDisabledColor
            },
            "&.MuiButton-textError": {
              color: colorsDark.baseCriticalColor,
              border: "transparent",
              "&:hover,&:hover,&:focus": {
                border: "1px solid " + colorsDark.baseCriticalColor,
                color: "white",
                background: colorsDark.baseCriticalColor,
                border: "transparent"
              },
              "&.MuiButton-root.MuiButton-text.Mui-disabled ": {
                color: colorsDark.tertiaryTextDisabledColor
              }
            }
          }
        }
      ]
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: "transparent",
          borderRadius: 0,
          "&.MuiIconButton-root.MuiIconButton-colorPrimary": {
            color: colorsDark.tertiaryTextDefaultColor,
            background: colorsDark.tertiaryBackgroundDefaultColor,
            "&:hover,&:active,&:focus": {
              borderRadius: 0,
              background: colorsDark.tertiaryBackgroundHoverColor,
              border: "transparent",
              color: colorsDark.tertiaryTextHoverColor
            },
            "&.Mui-disabled ": {
              color: colorsDark.tertiaryTextDisabledColor
            }
          },
          "&.Mui-disabled ": {
            color: colorsDark.tertiaryTextDisabledColor
          },
          "&.MuiIconButton-root.MuiIconButton-colorError": {
            color: colorsDark.baseCriticalColor,
            border: "transparent",
            "&:hover,&:hover,&:focus": {
              border: "1px solid " + colorsDark.baseCriticalColor,
              color: "white",
              background: colorsDark.baseCriticalColor,
              border: "transparent"
            },
            "&.Mui-disabled ": {
              color: colorsDark.tertiaryTextDisabledColor
            }
          },
          "&.MuiIconButton-root.MuiIconButton-colorSecondary": {
            color: "white",
            border: "transparent",
            "&:hover": {
              color: "white",
              background: "transparent",
              border: "1px solid " + colorsDark.borderHoverColor
            },
            "&:focus": {
              color: "white",
              background: colorsDark.baseVaporGradientColor,
              border: "transparent"
            }
          },
          "&.MuiButtonBase-root.MuiIconButton-root.IconButton-root:focus": {
            color: colorsDark.baseCriticalColor,
            borderRadius: 0,
            "&:focus": {
              borderRadius: 0,
              color: colorsDark.baseCriticalColor
            }
          },
          "&.MuiIconButton-edgeEnd": {
            marginRight: "-8px"
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiFormLabel-root.MuiInputLabel-root": {
            ...typographyTokens.bodyTypography,
            color: colorsDark.textDefaultColor,
            fontWeight: 500,
            margin: 0,
            marginBottom: "8px",
            position: "relative",
            transform: "unset",
            WebkitTransform: "unset",
            "&.Mui-disabled": {
              color: colorsDark.textDisabledColor
            }
          }
        }
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            "&.MuiFormLabel-root.MuiInputLabel-root": {
              marginBottom: 6
            }
          }
        }
      ]
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.MuiFormHelperText-root": {
            ...typographyTokens.captionTypography,
            color: colorsDark.textSubduedColor,
            marginLeft: 0,
            marginRight: 0,
            marginTop: "8px",
            "&.Mui-error": {
              color: colorsDark.textCriticalColor
            },
            "&.Mui-disabled": {
              color: colorsDark.textDisabledColor
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ...typographyTokens.bodyTypography,
          minHeight: 40,
          borderRadius: 0,
          padding: 0,
          background: colorsDark.inputBackgroundDefaultColor,
          color: colorsDark.textDefaultColor,
          "& legend": {
            display: "none"
          },
          "& fieldset": { top: 0 },
          "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active,input:-internal-autofill-selected":
            {
              backgroundColor: "red",
              " -webkit-box-shadow":
                "0 0 0 30px " +
                colorsDark.inputBackgroundDefaultColor +
                " inset !important"
            },
          "fieldset ": {
            borderColor: colorsDark.borderDefaultColor
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid",
            borderColor: colorsDark.borderDefaultColor,
            "fieldset ": {
              borderColor: colorsDark.borderDefaultColor
            }
          },
          "& .MuiFormLabel-root.MuiInputLabel-root": {
            margin: 0,
            ...typographyTokens.bodyTypography,
            color: colorsDark.textDefaultColor,
            fontWeight: 500,
            marginBottom: 8,
            position: "relative !important"
          },
          "&.Mui-focused": {
            background: colorsDark.inputBackgroundActiveColor
          },

          "&.MuiOutlinedInput-root": {
            "&:hover fieldset ": {
              border: "1px solid " + colorsDark.borderActiveColor, //set color border input hover
              "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline":
                {
                  borderColor: colorsDark.borderCriticalColor
                }
            },
            "&.Mui-focused fieldset": {
              border: "1px solid " + colorsDark.borderActiveColor
            }
          },

          "&.Mui-disabled": {
            backgroundColor:
              colorsDark.inputBackgroundDisabledColor + " !important",
            "&:hover fieldset ": {
              border:
                "1px solid " + colorsDark.borderDisabledColor + " !important",
              cursor: "not-allowed",
              pointerEvents: "all !important"
            },
            "&:hover fieldset ": {
              border:
                "1px solid " + colorsDark.borderDisabledColor + " !important"
            }
          },

          "& .Mui-disabled": {
            WebkitTextFillColor: colorsDark.textDisabledColor + " !important"
          },

          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            borderRadius: 0,
            padding: "10px 8px",
            height: "20px",
            "&:read-only": {
              backgroundColor:
                colorsDark.inputBackgroundReadonlyColor + " !important"
            },
            "&:hover": {
              cursor: "auto",
              "&:disabled": {
                cursor: "not-allowed",
                pointerEvents: "all !important"
              }
            }
          }
        }
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            minHeight: 32,
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              borderRadius: 0,
              padding: "6px 8px",
              height: "20px"
            },
            "& .MuiFormLabel-root.MuiInputLabel-root": {
              marginBottom: 6
            }
          }
        },
        {
          props: { error: true },
          style: {
            "&.MuiOutlinedInput-root": {
              fieldset: {
                border: "1px solid " + colorsDark.borderCriticalColor
              },
              "&.Mui-focused fieldset": {
                border: "1px solid " + colorsDark.borderCriticalColor
              },
              "&:hover fieldset ": {
                border: "1px solid " + colorsDark.borderCriticalColor, //set color border input hover
                "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid " + colorsDark.borderCriticalColor
                  }
              }
            }
          }
        }
      ]
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            color: colorsDark.textDefaultColor,
            ...typographyTokens.bodyTypography
          },
          "& .Mui-disabled": {
            color: colorsDark.textDisabledColor
          },

          "& .MuiFormControlLabel-label.Mui-disabled": {
            color: colorsDark.textDisabledColor
          }
        }
      }
    },

    MuiDivider: {
      variants: [
        {
          props: { variant: "fullWidth" },
          style: {
            backgroundColor: colorsDark.interactiveDefaultColor
          }
        }
      ]
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root.MuiIconButton-root": {
            color: colorsDark.iconDefaultColor,
            "&:read-only": {
              color: colorsDark.iconDisabledColor
            },
            "&.Mui-disabled": {
              color: colorsDark.iconDisabledColor
            }
          },
          "&.MuiPickersDay-root": {
            color: colorsDark.textDefaultColor,
            background: "transparent",
            ...typographyTokens.bodyTypography,
            border: "none"
          },
          "&.MuiPickersDay-root.MuiPickersDay-today": {
            background: "transparent",
            color: colorsDark.textDefaultColor,
            ...typographyTokens.bodyTypography,
            fontWeight: 700,
            border: "none"
          },
          "&.MuiPickersDay-root.Mui-selected": {
            background: colorsDark.baseVaporGradientColor,
            borderRadius: "50%",
            color: colorsDark.baseWhiteColor,
            ...typographyTokens.bodyTypography,
            fontWeight: 700,
            border: "none"
          },
          "&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall":
            {
              color: colorsDark.tertiaryTextDefaultColor
            },
          "&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall":
            {
              color: colorsDark.tertiaryTextDefaultColor
            },
          "&.MuiButtonBase-root.MuiMenuItem-root.MenuItem-root.Mui-selected": {
            background: colorsDark.interactiveSelectedColor
          },
          "&.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root":
            {
              background: colorsDark.interactiveSelectedColor,
              fontWeight: "400 !important"
            },
          "&.MuiButtonBase-root.MuiMenuItem-root": {
            ...typographyTokens.bodyTypography,
            border: "1px solid transparent"
          },
          "&.MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible": {
            background: colorsDark.interactiveSelectedColor,
            color: colorsDark.baseWhiteColor
          },
          "&.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.MuiButtonBase-root":
            {
              color: colorsDark.baseWhiteColor
            }
        }
      }
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          "&.MuiListSubheader-root.MuiListSubheader-gutters.MuiListSubheader-sticky":
            {
              color: colorsDark.textDefaultColor
            }
        }
      }
    },

    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          "&.MuiDateRangePickerDay-rangeIntervalDayHighlight": {
            background: colorsDark.interactiveSelectedColor
          },
          "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall":
            {
              color: colorsDark.tertiaryTextDefaultColor
            },
          "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            color: colorsDark.textDefaultColor,
            background: colorsDark.surfaceDefaultColor,
            "& .MuiSvgIcon-root": {
              color: colorsDark.tertiaryTextDefaultColor
            }
          }
        }
      }
    },

    MuiDateCalendar: {
      styleOverrides: {
        root: {
          ...typographyTokens.bodyTypography,
          color: colorsDark.textDefaultColor,
          background: colorsDark.surfaceDefaultColor,
          "& .MuiSvgIcon-root": {
            color: colorsDark.tertiaryTextDefaultColor
          },
          "& .MuiTypography-root.MuiTypography-caption": {
            ...typographyTokens.eyebrowTypography,
            color: colorsDark.textDisabledColor
          }
        }
      }
    },

    MuiYearCalendar: {
      styleOverrides: {
        root: {
          "& .PrivatePickersYear-root button": {
            color: colorsDark.textDefaultColor
          },

          "& .PrivatePickersYear-root .Mui-selected": {
            background: colorsDark.baseVaporGradientColor
          },
          "& .MuiPickersYear-yearButton.Mui-selected": {
            background: colorsDark.baseVaporGradientColor
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colorsDark.interactiveDefaultColor,
          ...typographyTokens.bodyTypography,
          "&.MuiLink-underlineAlways": {
            textDecoration: "underline",
            textDecorationColor: colorsDark.interactiveDefaultColor
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
            ...typographyTokens.bodyTypography,
            color: colorsDark.textDefaultColor,
            background: colorsDark.surfaceDefaultColor
          },
          "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            color: colorsDark.textDefaultColor,
            background: colorsDark.surfaceDefaultColor
          }
        }
      },
      variants: [
        {
          props: { variant: "menuItem" },
          style: {
            "&.MuiPaper-root.MuiPaper-menuItem.MuiPopover-paper": {
              top: "48px !important",
              left: "48px !important",
              width: 200,
              height: "100%",
              background: colorsDark.surfaceDefaultColor,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        }
      ]
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
          background: colorsDark.backgroundDefaultColor
        }
      },
      variants: [
        {
          props: { variant: "navigation" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-root": {
              width: "100%",
              position: "fixed",
              background: colorsDark.primary90Color
            }
          }
        },
        {
          props: { variant: "regular" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "small" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 48,
              height: 48,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "medium" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "regular", size: "large" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 72,
              height: 72,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "surface" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56
            }
          }
        },
        {
          props: { variant: "surface", size: "small" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 48,
              height: 48,
              boxShadow: "0px 4px 16px rgba(21, 41, 53, 0.25)"
            }
          }
        },
        {
          props: { variant: "surface", size: "medium" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 56,
              height: 56
            }
          }
        },
        {
          props: { variant: "surface", size: "large" },
          style: {
            "&.MuiToolbar-root.MuiToolbar-gutters": {
              minHeight: 72,
              height: 72
            }
          }
        }
      ]
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 2,
          backgroundColor: colorsDark.backgroundProminentColor,
          ...typographyTokens.captionTypography,
          color: "#ffffff"
        },
        arrow: {
          "&::before": {
            backgroundColor: colorsDark.backgroundProminentColor
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "&.MuiInputAdornment-root": {
            marginLeft: 8,
            marginRight: 8,
            "& .MuiTypography-root": {
              ...typographyTokens.bodyTypography,
              color: colorsDark.textDefaultColor
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#0090D1",
          "&.MuiCheckbox-root.Mui-checked": {
            color: "#0090D1"
          },
          "&.MuiCheckbox-root.Mui-disabled": {
            color: colorsDark.textDisabledColor
          },
          "&.MuiCheckbox-root.Mui-disabled.Mui-checked": {
            color: colorsDark.textDisabledColor
          },
          "&.MuiCheckbox-root.MuiCheckbox-indeterminate": {
            color: "#0090D1"
          },
          "&.MuiCheckbox-root.MuiCheckbox-indeterminate.Mui-disabled.Mui-checked":
            {
              color: colorsDark.textDisabledColor
            }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colorsDark.textDefaultColor
        },
        root: {
          zIndex: 4
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: zIndex.drawer,
          "& .MuiListItemIcon-root": {
            color: "#fff"
          },
          "& .MuiListItemText-root": {
            color: "#fff"
          }
        },
        paper: {
          backgroundColor: colorsDark.textDefaultColor
        }
      }
    },
    MuiAccordion: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            "&.MuiAccordion-root": {
              border: "1px solid " + colorsDark.borderCriticalColor
            },
            "&.MuiAccordion-root .MuiAccordionSummary-content": {
              marginRight: 0
            },
            "&.MuiAccordion-root .MuiAccordionSummary-root .error-icon": {
              display: "block"
            }
          }
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: "14px",
          color: colorsDark.textDefaultColor
        },
        option: {
          "&:hover": {
            border: "1px solid " + colorsDark.interactiveDefaultColor,
            backgroundColor: colorsDark.surfaceDefaultColor + " !important"
          },
          '&[aria-selected="true"]': {
            backgroundColor: "#DEF0F7 !important",
            color: "#005075"
          },
          "& .MuiAutocomplete-option.MuiFocused": {
            backgroundColor: "#DEF0F7 !important"
          },
          border: "1px solid transparent"
        },
        root: {
          "& .MuiAutocomplete-option.MuiFocused": {
            backgroundColor: "#DEF0F7 !important"
          }
        }
      }
    },
    MuiTabs: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 72
            }
          }
        },
        {
          props: { orientation: "vertical" },
          style: {
            "&.MuiTabs-root.MuiTabs-vertical": {
              "& .MuiTab-root:hover": {
                background: colorsDark.interactiveSelectedColor
              }
            }
          }
        },
        {
          props: { variant: "sticky", orientation: "vertical" },
          style: {
            "&.MuiTabs-root.MuiTabs-vertical": {
              right: "0 !important",
              "& .MuiTab-root:hover": {
                background: colorsDark.interactiveSelectedColor
              }
            }
          }
        },
        {
          props: { variant: "standard", size: "medium" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 72
            }
          }
        },
        {
          props: { variant: "standard", size: "small" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              "& .MuiTab-root": {
                fontSize: 14
              }
            }
          }
        },
        {
          props: { variant: "scrollable", size: "small" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              "& .MuiTab-root": {
                fontSize: 14
              }
            }
          }
        },
        {
          props: { variant: "secondary" },
          style: {
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator": {
              height: 0
            },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
              height: 48,
              alignItems: "center"
            },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root":
              {
                padding: "4px 12px",
                margin: "0 12px",
                fontSize: 14,
                fontFamily: "Cairo",
                fontWeight: 600,
                color: colorsDark.textSubduedColor,
                ":hover": {
                  minHeight: 32,
                  height: 32,
                  background: colorsDark.interactiveDefaultColor,
                  color: colorsDark.baseWhiteColor,
                  padding: "4px 12px",
                  borderBottom: "0 !important",
                  borderRadius: 2
                }
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-selected":
              {
                minHeight: 32,
                height: 32,
                background: "#B9E6F6",
                color: colorsDark.textTitleColor,
                padding: "4px 12px",
                borderBottom:
                  "1px solid " +
                  colorsDark.interactiveDefaultColor +
                  " !important",
                border: "1px solid " + colorsDark.interactiveDefaultColor,
                borderRadius: 2
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-disabled":
              {
                margin: "0 12px",
                color: "#D8D8D9",
                border: "none",
                background: "transparent"
              },
            "&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root.Mui-disabled:hover":
              {
                background: "transparent",
                borderBottom: "0 !important"
              }
          }
        }
      ]
    },
    MuiTab: {
      variants: [
        {
          props: { variant: "activeStep" },
          style: {
            "&.MuiTab-root	": {
              borderBottomWidth: 106,
              paddingRight: "48px !important",
              clipPath:
                "polygon(0% 0%, calc(100% - 24px) 0%, 100% 50%, calc(100% - 24px) 100%, 0% 100%)"
            }
          }
        }
      ]
    },

    MuiChip: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentRussianVioletColor,
              borderRadius: 4,
              padding: "4px 8px",
              height: 24,
              "& .MuiChip-label": {
                ...typographyTokens.captionTypography,
                color: colorsDark.baseWhiteColor,
                padding: 0
              },
              ":hover": {
                background: colorsDark.accentRussianVioletColor,
                "& .MuiChip-label": {
                  ...typographyTokens.captionTypography,
                  color: colorsDark.baseWhiteColor,
                  padding: 0
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "blueSapphire" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentBlueSapphireColor,
              ":hover": {
                background: colorsDark.accentBlueSapphireColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "islamicGreen" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentIslamicGreenColor,
              ":hover": {
                background: colorsDark.accentIslamicGreenColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "russianViolet" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentRussianVioletColor,
              ":hover": {
                background: colorsDark.accentRussianVioletColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "maximumPurple" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentMaximumPurpleColor,
              ":hover": {
                background: colorsDark.accentMaximumPurpleColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "mediumSlateBlue" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentMediumSlateBlueColor,
              ":hover": {
                background: colorsDark.accentMediumSlateBlueColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "prune" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentPruneColor,
              ":hover": {
                background: colorsDark.accentPruneColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "royalFuchsia" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentRoyalFuchsiaColor,
              ":hover": {
                background: colorsDark.accentRoyalFuchsiaColor
              }
            }
          }
        },
        {
          props: { variant: "standard", type: "rustyRed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentRustyRedColor,
              ":hover": {
                background: colorsDark.accentRustyRedColor
              }
            }
          }
        },
        // ghost
        {
          props: { variant: "ghost" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: "transparent",
              border: "1px solid " + colorsDark.accentRussianVioletColor,
              borderRadius: 4,
              height: 24,
              padding: "4px 8px",
              "& .MuiChip-label": {
                ...typographyTokens.captionTypography,
                color: colorsDark.accentRussianVioletColor,
                padding: 0
              },
              ":hover": {
                background: "transparent",
                "& .MuiChip-label": {
                  ...typographyTokens.captionTypography,
                  color: colorsDark.accentRussianVioletColor,
                  padding: 0
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "blueSapphire" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsDark.accentBlueSapphireColor,
              border: "1px solid " + colorsDark.accentBlueSapphireColor,
              "& .MuiChip-label": {
                color: colorsDark.accentBlueSapphireColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsDark.accentBlueSapphireColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "islamicGreen" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsDark.accentIslamicGreenColor,
              border: "1px solid " + colorsDark.accentIslamicGreenColor,
              "& .MuiChip-label": {
                color: colorsDark.accentIslamicGreenColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsDark.accentIslamicGreenColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "russianViolet" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsDark.accentRussianVioletColor,
              border: "1px solid " + colorsDark.accentRussianVioletColor,
              "& .MuiChip-label": {
                color: colorsDark.accentRussianVioletColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsDark.accentRussianVioletColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "maximumPurple" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsDark.accentMaximumPurpleColor,
              border: "1px solid " + colorsDark.accentMaximumPurpleColor,
              "& .MuiChip-label": {
                color: colorsDark.accentMaximumPurpleColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsDark.accentMaximumPurpleColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "mediumSlateBlue" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsDark.accentMediumSlateBlueColor,
              border: "1px solid " + colorsDark.accentMediumSlateBlueColor,
              "& .MuiChip-label": {
                color: colorsDark.accentMediumSlateBlueColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsDark.accentMediumSlateBlueColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "prune" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsDark.accentPruneColor,
              border: "1px solid " + colorsDark.accentPruneColor,
              "& .MuiChip-label": {
                color: colorsDark.accentPruneColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsDark.accentPruneColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "royalFuchsia" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsDark.accentRoyalFuchsiaColor,
              border: "1px solid " + colorsDark.accentRoyalFuchsiaColor,
              "& .MuiChip-label": {
                color: colorsDark.accentRoyalFuchsiaColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsDark.accentRoyalFuchsiaColor
                }
              }
            }
          }
        },
        {
          props: { variant: "ghost", type: "rustyRed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              color: colorsDark.accentRustyRedColor,
              border: "1px solid " + colorsDark.accentRustyRedColor,
              "& .MuiChip-label": {
                color: colorsDark.accentRustyRedColor
              },
              ":hover": {
                "& .MuiChip-label": {
                  color: colorsDark.accentRustyRedColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "completed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.baseSuccessColor,
              "& .MuiChip-label": {
                color: colorsDark.baseWhiteColor
              },
              ":hover": {
                background: colorsDark.baseSuccessColor,
                "& .MuiChip-label": {
                  color: colorsDark.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "draft" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.neutral50Color,
              "& .MuiChip-label": {
                color: colorsDark.baseWhiteColor
              },
              ":hover": {
                background: colorsDark.neutral50Color,
                "& .MuiChip-label": {
                  color: colorsDark.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "processing" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.baseInfoColor,
              "& .MuiChip-label": {
                color: colorsDark.baseWhiteColor
              },
              "& .MuiChip-icon": {
                color: colorsDark.baseWhiteColor,
                margin: 0,
                marginRight: 8,
                fontSize: 16
              },
              ":hover": {
                background: colorsDark.baseInfoColor,
                "& .MuiChip-icon": {
                  color: colorsDark.baseWhiteColor,
                  margin: 0,
                  marginRight: 8,
                  fontSize: 16
                },
                "& .MuiChip-label": {
                  color: colorsDark.baseWhiteColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "confirmed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.baseWarningColor,
              "& .MuiChip-label": {
                color: colorsDark.textDefaultColor
              },
              ":hover": {
                background: colorsDark.baseWarningColor,
                "& .MuiChip-label": {
                  color: colorsDark.textDefaultColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "reversing" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.baseWarningColor,
              "& .MuiChip-label": {
                color: colorsDark.textDefaultColor
              },
              ":hover": {
                background: colorsDark.baseWarningColor,
                "& .MuiChip-label": {
                  color: colorsDark.textDefaultColor
                }
              }
            }
          }
        },
        {
          props: { variant: "standard", status: "reversed" },
          style: {
            "&.MuiChip-root.MuiChip-root": {
              background: colorsDark.accentMaximumPurpleColor,
              "& .MuiChip-label": {
                color: colorsDark.baseWhiteColor
              },
              ":hover": {
                background: colorsDark.accentMaximumPurpleColor,
                "& .MuiChip-label": {
                  color: colorsDark.baseWhiteColor
                }
              }
            }
          }
        }
      ]
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiTypography-root.MuiTypography-root.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary":
            {
              color: colorsDark.textDefaultColor
            }
        }
      }
    }
  }
});
export default darkTheme;
