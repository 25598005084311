function hexToRGB(value) {
  var hex = value.slice(5, 12);
  var alpha = value.slice(14, 17);

  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function colorsToTokens(theme) {
  let variablesNew = {};

  for (var k in theme) {
    for (var z in theme[k]) {
      if (theme[k][z].value && theme[k][z].value.includes("hsl")) {
        var idx = theme[k][z].value.indexOf("$");
        var idx1 = theme[k][z].value.indexOf(".");
        var idx2 = theme[k][z].value.indexOf(",");
        var idx3 = theme[k][z].value.indexOf("(");
        var name = theme[k][z].value.substring(idx + 1, idx1);
        var name2 = theme[k][z].value.substring(idx2, idx1 + 1);
        if (name && name2) {
          theme[k][z].value =
            theme[k][z].value.substring(0, idx3 + 1) +
            theme[name][name2].value +
            theme[k][z].value.substring(idx2);
        }
      } else if (theme[k][z].value && theme[k][z].value.includes("{")) {
        var idx = theme[k][z].value.indexOf("{");
        var idx1 = theme[k][z].value.indexOf(".");
        var idx2 = theme[k][z].value.indexOf("}");

        var name = theme[k][z].value.substring(idx + 1, idx1);
        var name2 = theme[k][z].value.substring(idx1 + 1, idx2);
        if (name && name2 && theme[name][name2]?.value) {
          theme[k][z].value =
            theme[k][z].value.substring(0, idx + 1) +
            theme[name][name2].value +
            theme[k][z].value.substring(idx2);
          theme[k][z].value = theme[k][z].value.replace("{", "");
          theme[k][z].value = theme[k][z].value.replace("}", "");
        }
      }

      if (
        (theme[k][z].value && theme[k][z].value.includes("rgb")) ||
        (theme[k][z].value && theme[k][z].value.includes("rgba"))
      ) {
        if (theme[k][z].value.includes("#")) {
          theme[k][z].value = hexToRGB(theme[k][z].value);
        }
      }
    }
  }

  for (var i in theme) {
    // Create colors variables
    for (var j in theme[i]) {
      if (typeof theme[i][j] !== "string" && theme[i][j].type) {
        variablesNew[
          i +
            (j.charAt(0).toUpperCase() + j.slice(1)) +
            (theme[i][j].type.charAt(0).toUpperCase() +
              theme[i][j].type.slice(1))
        ] = theme[i][j].value;
      }
    }
  }

  return variablesNew;
}

export function fontsToTokens(fonts) {
  let variablesNew = {};

  for (var i in fonts) {
    if ("type" in fonts[i]) {
      variablesNew[
        i + (fonts[i].type.charAt(0).toUpperCase() + fonts[i].type.slice(1))
      ] = fonts[i].value;
    } else {
      for (var j in fonts[i]) {
        variablesNew[
          i +
            (j.charAt(0).toUpperCase() + j.slice(1)) +
            (fonts[i][j].type.charAt(0).toUpperCase() +
              fonts[i][j].type.slice(1))
        ] = fonts[i][j].value;
      }
    }
  }

  // transform name from textCase to textTransform
  for (var objectTyp in variablesNew) {
    for (const key in variablesNew[objectTyp]) {
      if (key === "textCase") {
        variablesNew[objectTyp].textTransform =
          variablesNew[objectTyp].textCase;
        delete variablesNew[objectTyp].textCase;
      }
    }
  }

  return variablesNew;
}
